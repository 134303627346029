import React from 'react';
import { Dialog, DialogContent, Slide, DialogActions, Button, TextField } from '@mui/material';
import {isGestionPsy} from "../../../../service/security/hasRoles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CommentPopup({ open, handleClose, text, setText, handleAccept, darkState}) {

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="change-date-popup-title"
            aria-describedby="change-date-popup-description"
        >
            {!isGestionPsy() ? (
                <DialogContent style={{ paddingBottom: 20 }}>
                    {open.remark ? open.remark : 'Aucun commentaire disponible.'}
                </DialogContent>
            ) : (
                <>
                    <DialogContent style={{ paddingBottom: 20 }}>
                        <TextField multiline rows={4} label='Commentaire' color={darkState ? 'secondary' : 'primary'} value={text} onChange={(e) => setText(e.target.value)} />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={typeof text === 'string' && !text.length} variant={'contained'} color={darkState ? 'secondary' : 'primary'} onClick={handleAccept}>Enregistrer</Button>
                    </DialogActions>
                </>
            )}
            <DialogActions>
                <Button onClick={handleClose} color={darkState ? 'secondary' : 'primary'}>
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}