import React, { useEffect, useState } from 'react'
import {
    Chip,
    LinearProgress,
    Button,
    Accordion,
    Typography,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Tooltip,
    Zoom,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material/';
import { apiFetch } from '../service/apiFetch';
import { removeLastAmpersand, TransitionLeft } from '../helpers/utils';
import FilterListIcon from '@mui/icons-material/FilterList';
import AutoCompleteAddress from './AutoCompleteAddress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

function FilterList({
    hasFilter,
    setDisableFilterList,
    disableFilterList,
    setUpdateFilter,
    darkState,
    filterList,
    state,
    setState,
    setOfficeSpecialistType,
    setOfficeSpecialistSpecialities,
    setOfficeSpecialistTarget,
    setFirstName,
    setLastName,
    setNewCoordonnate
}) {

    const handleRemoveFilter = (e, list, item) => {
        if (disableFilterList) return
        setDisableFilterList(true)
        const func = findRightFunction(
            item,
            setOfficeSpecialistType,
            setOfficeSpecialistSpecialities,
            setOfficeSpecialistTarget,
            setFirstName,
            setLastName,
            setNewCoordonnate
        )
        if (list.length < 2) {
            func("")
        } else if (typeof item === 'string') {
            setState({ ...state, [item]: false });
        } else {
            const remove = list.filter((i) => i.id !== item.id)
            func(remove)
        }
        setUpdateFilter(true)
    }

    const findRightFunction = (
        key,
        setOfficeSpecialistType,
        setOfficeSpecialistSpecialities,
        setOfficeSpecialistTarget,
        setFirstName,
        setLastName,
    ) => {
        switch(key['@type']) {
            case "specialistType":
                return setOfficeSpecialistType
            case "specialities":
                return setOfficeSpecialistSpecialities
            case "targetAudiences":
                return setOfficeSpecialistTarget
            case 'firstName':
                return setFirstName
            case 'lastName':
                return setLastName
            case 'ville':
                return setNewCoordonnate
            default:
                return false
        }
    }

    return (
        <>
            {hasFilter() && <><Typography style={{width: 225, marginTop: 15}}>Filtre(s) en cours : </Typography>
                <div style={{display: 'flex', flexWrap: 'wrap', flex: '1 1 100%', marginTop: 15}}>
                    {filterList && !!filterList.length && filterList.map((list, i) => {
                        return <React.Fragment key={i}>
                            {list && list.length > 0 && list.map((item, index) => (
                                    item && item.name && <div key={index} style={{display: 'flex', marginRight: 10, marginBottom: 10}}>
                                        <Chip
                                            size="small"
                                            label={item.name}
                                            onDelete={(e) => handleRemoveFilter(e, list, item)}
                                            color={darkState ? 'secondary' : 'primary'}
                                        />
                                    </div>
                                ))}
                        </React.Fragment>
                    })}
                </div>
            </>}
        </>
    )
}

export default function OfficeSpecialistFilters({
    isFromMap,
    setNewCoordonnate,
    newCoordonnate,
    isFromList,
    setIsFiltered,
    setAllOfficeSpecialists,
    handleExportSpecialistOffice,
    filterList,
    setFilterList,
    setNoOfficeSpecialist,
    state,
    setState,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    officeSpecialistType,
    setOfficeSpecialistType,
    officeSpecialistSpecialities,
    setOfficeSpecialistSpecialities,
    officeSpecialistTarget,
    setOfficeSpecialistTarget,
    phoneResponsive,
    fetchOfficeSpecialists,
    setRefresh,
    setOfficeSpecialists,
    darkState,
    expanded,
    setExpanded,
    zoom,
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity
}) {
    const [disabledFilter, setDisabledFilter] = useState(false)
    const [disableFilterList, setDisableFilterList] = useState(false)
    const [updateFilter, setUpdateFilter] = useState(false)

    useEffect(() => {
        (async () => {
            if (filterList) {
                await handleFilter()
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[
        setOpenSnackbar,
        setText,
        setTransition,
        setSeverity,
    ])

    const handleFilter = async () => {
        setDisabledFilter(true)
        setUpdateFilter(false)
        const officeSpecialistTypeFilter = officeSpecialistType ? `specialistType=${officeSpecialistType}&` : ''
        const officeSpecialistSpecialitiesFilter = officeSpecialistSpecialities ? `specialities={${officeSpecialistSpecialities}}&`: ''
        const officeSpecialistTargetFilter = officeSpecialistTarget ? `targetAudiences={${officeSpecialistTarget}}&`: ''
        const foundFirstName = firstName ? `firstName=${firstName}&` : ''
        const foundLastName = lastName ? `lastName=${lastName}&` : ''
        const foundCity = newCoordonnate ? `distance_from=${newCoordonnate.lat},${newCoordonnate.lng}&` : ''
        const url = removeLastAmpersand(`/office_specialists?${officeSpecialistTypeFilter}${officeSpecialistSpecialitiesFilter}${officeSpecialistTargetFilter}${foundFirstName}${foundLastName}${foundCity}pagination=false`)

        setFilterList([
            [{'@type': 'firstName', name: firstName}],
            [{'@type': 'lastName', name: lastName}],
            [{'@type': 'specialistType', name: officeSpecialistType}],
            [{'@type': 'specialities', name: officeSpecialistSpecialities}],
            [{'@type': 'targetAudiences', name: officeSpecialistTarget}],     
            newCoordonnate ? [{'@type': 'ville', name: 'Ville'}] : false
        ])
        setRefresh && setRefresh(true)
        const filtered = fetchOfficeSpecialists ? await fetchOfficeSpecialists(url) : await apiFetch(url)
        if (filtered) {
            if (filtered.length === 0) setNoOfficeSpecialist && setNoOfficeSpecialist(true)
            setIsFiltered && setIsFiltered(true)
            setOfficeSpecialists && setOfficeSpecialists(filtered)
            setAllOfficeSpecialists && setAllOfficeSpecialists(filtered)
        } else {
            setText((officeSpecialistType && officeSpecialistType['hydra:description']) || 'Erreur lors de la filtration des données')
            setText((officeSpecialistSpecialities && officeSpecialistSpecialities['hydra:description']) || 'Erreur lors de la filtration des données')
            setText((officeSpecialistTarget && officeSpecialistTarget['hydra:description']) || 'Erreur lors de la filtration des données')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setRefresh && setRefresh(false)
        setExpanded(false)
        setDisabledFilter(false)
        setDisableFilterList(false)
    }

    const handleDeleteFilter = async () => {
        setDisabledFilter(true)
        const deleteFiltered = fetchOfficeSpecialists ?
            await fetchOfficeSpecialists(`/office_specialists`) :
            await apiFetch(`/office_specialists`)

        if (deleteFiltered && (deleteFiltered.length || deleteFiltered['hydra:member'])) {
            setOfficeSpecialistType('')
            setOfficeSpecialistSpecialities('')
            setOfficeSpecialistTarget('')
            setFilterList && setFilterList(false)
            setOfficeSpecialists && setOfficeSpecialists(deleteFiltered)
            setAllOfficeSpecialists && setAllOfficeSpecialists(deleteFiltered)
        } else {
            setText((officeSpecialistType && officeSpecialistType['hydra:description']) || 'Erreur lors de la récupération des données')
            setText((officeSpecialistSpecialities && officeSpecialistSpecialities['hydra:description']) || 'Erreur lors de la récupération des données')
            setText((officeSpecialistTarget && officeSpecialistTarget['hydra:description']) || 'Erreur lors de la récupération des données')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setRefresh && setRefresh(false)
        setExpanded(!expanded)
        setDisabledFilter(false)
    }

    const hasFilter = () => {
        return (firstName ||
            lastName ||
            officeSpecialistType || officeSpecialistSpecialities || officeSpecialistTarget ||
            newCoordonnate)
    }

    return (
        <Zoom in={zoom} style={{ transitionDelay: '1000ms' }}>
            <Accordion
                style={phoneResponsive ?
                    {
                        marginBottom: 30,
                        boxShadow: '0 4px 4px 0 rgb(34 41 47 / 10%)',
                        borderRadius: 4,
                        maxWidth: 257
                    } :
                    {
                        marginBottom: 30,
                        boxShadow: '0 4px 4px 0 rgb(34 41 47 / 10%)',
                        borderRadius: 4
                    }
                }
                expanded={expanded}
            >
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{cursor: 'default'}}
                >
                    {updateFilter && handleFilter()}
                    <Typography
                        style={expanded || !hasFilter() ? {flex: '1 1 100%',} : {}}
                        color={darkState ? 'secondary' : 'primary'}
                        sx={{
                            alignItems: 'center',
                            fontSize: 24,
                            mt: 1,
                            mr: {sm: 10, xs: 2},
                        }}
                    >
                        Filtres
                    </Typography>
                    {!expanded && <FilterList
                        setNewCoordonnate={setNewCoordonnate}
                        hasFilter={hasFilter}
                        disableFilterList={disableFilterList}
                        setUpdateFilter={setUpdateFilter}
                        setDisableFilterList={setDisableFilterList}
                        handleFilter={handleFilter}
                        setFilterList={setFilterList}
                        state={state}
                        setState={setState}
                        officeSpecialistType={officeSpecialistType}
                        setOfficeSpecialistType={setOfficeSpecialistType}
                        officeSpecialistSpecialities={officeSpecialistSpecialities}
                        setOfficeSpecialistSpecialities={setOfficeSpecialistSpecialities}
                        officeSpecialistTarget={officeSpecialistTarget}
                        setOfficeSpecialistTarget={setOfficeSpecialistTarget}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        darkState={darkState}
                        filterList={filterList}
                    />}
                     {handleExportSpecialistOffice && <IconButton style={phoneResponsive ? {marginRight: -15} : {}} onClick={handleExportSpecialistOffice}>
                      <Tooltip TransitionComponent={Zoom} title={`Exporter les spécialistes cabinet`}>
                         <CloudDownloadIcon />
                      </Tooltip>
                      </IconButton>}
                    <IconButton color='inherit' style={phoneResponsive ? {marginRight: -15} : {}} onClick={() => setExpanded(!expanded)}>
                        <Tooltip TransitionComponent={Zoom} title={'Liste des filtres'}>
                            <FilterListIcon />
                        </Tooltip>
                    </IconButton>
                   
                     
                </AccordionSummary>
                <AccordionDetails>
  <Grid container spacing={3}>
    {/* Titre */}
    <Grid item xs={12}>
      <Typography color={darkState ? 'secondary' : 'primary'}>
        Données communes
      </Typography>
    </Grid>

    {/* Champs sur une seule ligne */}
    <Grid container item xs={12} spacing={2} alignItems="center">
      {/* Champ Ville */}
      {isFromMap && (
        <Grid item xs={12} sm={3}>
          <AutoCompleteAddress
            setRefresh={setRefresh}
            setExpanded={setExpanded}
            darkState={darkState}
            id="city"
            name="city"
            label="Ville"
            defaultValue=""
            setNewCoordonnate={setNewCoordonnate}
            fullWidth
          />
        </Grid>
      )}
      {isFromList && (
        <Grid item xs={12} sm={3}>
          <AutoCompleteAddress
            setRefresh={setRefresh}
            darkState={darkState}
            id="city"
            name="city"
            label="Ville"
            defaultValue=""
            setNewCoordonnate={setNewCoordonnate}
            fullWidth
          />
        </Grid>
      )}

      {/* Champ Type de spécialistes */}
      <Grid item xs={12} sm={3}>
        <FormControl color={darkState ? 'secondary' : 'primary'} fullWidth>
          <InputLabel id="specialist-type-label">Type de spécialistes</InputLabel>
          <Select
            labelId="specialist-type-label"
            id="specialist-type"
            value={officeSpecialistType || ''}
            onChange={(e) => setOfficeSpecialistType(e.target.value)}
            label="Type de spécialistes"
          >
            <MenuItem value="">Sélectionner</MenuItem>
            <MenuItem value="Psychologue clinicien">Psychologue clinicien</MenuItem>
            <MenuItem value="Psychologue du travail">Psychologue du Travail</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Champ Public cible */}
      <Grid item xs={12} sm={3}>
        <FormControl color={darkState ? 'secondary' : 'primary'} fullWidth>
          <InputLabel id="public-cible-label">Public(s) ciblé(s)</InputLabel>
          <Select
            labelId="public-cible-label"
            id="public-cible"
            value={officeSpecialistTarget || ''}
            onChange={(e) => setOfficeSpecialistTarget(e.target.value)}
            label="Public(s) cible(s)"
          >
            <MenuItem value="">Sélectionner</MenuItem>
            <MenuItem value="Enfants">Enfants</MenuItem>
            <MenuItem value="Adolescents">Adolescents</MenuItem>
            <MenuItem value="Adultes">Adultes</MenuItem>
            <MenuItem value="Couples">Couples</MenuItem>
            <MenuItem value="Familles">Familles</MenuItem>
            <MenuItem value="Aidants familiaux">Aidants familiaux</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Champ Spécialité */}
      <Grid item xs={12} sm={3}>
        <FormControl color={darkState ? 'secondary' : 'primary'} fullWidth>
          <InputLabel id="specialite-label">Spécialité(s)</InputLabel>
          <Select
            labelId="specialite-label"
            id="specialite"
            value={officeSpecialistSpecialities || ''}
            onChange={(e) => setOfficeSpecialistSpecialities(e.target.value)}
            label="Spécialité(s)"
          >
            <MenuItem value="">Sélectionner</MenuItem>
            <MenuItem value="Prévention des risques psycho-sociaux">
              Prévention des risques psycho-sociaux
            </MenuItem>
            <MenuItem value="Travail">Travail</MenuItem>
            <MenuItem value="Souffrance au travail">
              Souffrance au travail
            </MenuItem>
            <MenuItem value="Harcèlement">Harcèlement</MenuItem>
            <MenuItem value="Deuil">Deuil</MenuItem>
            <MenuItem value="Trauma">Trauma</MenuItem>
            <MenuItem value="Santé">Santé</MenuItem>
            <MenuItem value="Addictions">Addictions</MenuItem>
            <MenuItem value="Handicap">Handicap</MenuItem>
            <MenuItem value="Conflit (professionnel)">
              Conflit (professionnel)
            </MenuItem>
            <MenuItem value="Coaching">Coaching</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>

    {/* Boutons */}
    <Grid item xs={12} style={{ textAlign: 'center' }}>
      <Button variant="contained" color={darkState ? 'secondary' : 'primary'} disabled={disabledFilter} onClick={handleFilter} sx={{ mx: 1 }}>
       Filtrer
      </Button>
      <Button variant="contained" color={darkState ? 'secondary' : 'primary'} disabled={disabledFilter} onClick={handleDeleteFilter} sx={{ mx: 1 }}>
        Supprimer les filtres
      </Button>
    </Grid>
  </Grid>
</AccordionDetails>
                {disabledFilter && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
            </Accordion>
        </Zoom>
    )
}
