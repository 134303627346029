import React, { useEffect, useState } from 'react';
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    IconButton,
    Tooltip,
    CircularProgress,
    Zoom,
    createTheme,
    ThemeProvider,
    ButtonGroup,
    LinearProgress
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import { download, TransitionLeft } from '../../../helpers/utils';
import GetAppIcon from '@mui/icons-material/GetApp';
import * as locales from '@mui/material/locale';
import moment from 'moment'
import { useContracts } from '../../../hooks/contracts';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { apiFetch } from '../../../service/apiFetch';
import AddContract from './ContractItem/AddContract';
import { handleFormError } from '../../../service/security/formError';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import DeletedConfirmationPopup from '../../../components/DeletedConfirmationPopup';
import {isGestionPsy} from "../../../service/security/hasRoles";

const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Nom du contrat' },
    { id: 'contratType', numeric: false, disablePadding: false, label: 'Type de contrat' },
    { id: 'endDate', numeric: false, disablePadding: true, label: 'Date de fin' },
    { id: 'startDate', numeric: false, disablePadding: true, label: 'Date de début' },
    { id: 'archive', numeric: true, disablePadding: false, label: 'Archive' },
    ...(isGestionPsy() ? [{ id: 'action', numeric: true, disablePadding: false, label: 'Action' }] : []),
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, darkState } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{fontWeight: 'bold', color: darkState ? '#7fe1dd' : '#12047D' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span
                                    style={{
                                        border: 0,
                                        clip: 'rect(0 0 0 0)',
                                        height: 1,
                                        margin: -1,
                                        overflow: 'hidden',
                                        padding: 0,
                                        position: 'absolute',
                                        top: 20,
                                        width: 1,
                                    }}
                                >
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const { handleOpenAddPopup } = props;

    return (
        <Toolbar>
           
            <Typography variant="h6" id="tableTitle" component="div" sx={{flex: '1 1 100%'}}>
                Contrats
            </Typography>

            {isGestionPsy() && (
                <Tooltip TransitionComponent={Zoom} title="Ajouter un contrat">
                    <IconButton color='inherit' aria-label="filter list" onClick={handleOpenAddPopup}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

export default function Contracts({ setOpenSnackbar, setText, setTransition, setSeverity, darkState, zoom }) {
    const [page, setPage] = useState(0);
    const [openAddPopup, setOpenAddPopup] = useState(false);
    const [name, setName] = useState('');
    const [error, setError] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [contractTypes, setContractTypes] = useState(false);
    const [contractType, setContractType] = useState('');
    const [loading, setLoading] = useState(false)
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [selected, setSelected] = useState(false)
    const [locale] = useState('frFR');
    const [loadingPage, setLoadingPage] = useState(false);
    const { id } = useParams()
    let navigate = useNavigate()

    const {
        contracts,
        totalItems,
        view,
        fetchContracts,
        createContract,
        updateContract,
        deleteContract,
    } = useContracts()
    
    useEffect(() => {
        (async () => {
            const contractFetch = await fetchContracts(`${id}`)
            if (contractFetch && contractFetch['hydra:description']) {
                setText('Error lors de la récupération des données')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/specialists')
            }
            const contractTypesFetch = await apiFetch('/contract_types')
            if (contractTypesFetch && contractTypesFetch['hydra:member']) {
                setContractTypes(contractTypesFetch['hydra:member'])
            } else {
                setText('Error lors de la récupération des données')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/specialists')
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangePage = async (event, newPage) => {
        setLoadingPage(true)
        if (newPage > page) {
          await fetchContracts(view['hydra:next'])
        } else if (newPage < page) {
          await fetchContracts(view['hydra:previous'])
        }
        setLoadingPage(false)
        setPage(newPage);
      };

    const handleConfirmationDelete = (row) => {
        setSelected(row)
        setOpenDeletePopup(!openDeletePopup)
    }


    const handleOpenAddPopup = () => {
        setOpenAddPopup(!openAddPopup)
    }

    const handleAddContract = async () => {
        setLoading(true)
        setError(false)
        if (!contractType) {
            setLoading(false)
            return setError({contractType: 'vous devez sélectionner un type de contrat'})
        }
        const createdContract = await createContract({
            name: name,
            startDate: startDate,
            endDate: endDate,
            contractType: contractType,
            specialist: `/specialists/${id}`
        })
        if (createdContract && createdContract.uuid) {
            setText(`le contrat a été crée`)
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setOpenAddPopup(false)
            setName(false)
            setStartDate(null)
            setEndDate(null)
            setContractType('')
        } else {
            const errorField = handleFormError(createdContract, ['name', 'startDate', 'endDate', 'contractType'])
            setError(errorField)
            setText(`Erreur lors de la création du contrat`)
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setLoading(false)
    }

    const handleChangeContractTypes = (e) => {
        setContractType(e.target.value);
    }

    const handleDownload = async (row) => {
        const fetchBlob = await apiFetch(`/contracts/download/${row.uuid}`, {}, false, false, true)

        if (fetchBlob.ok) {
          const blob = await fetchBlob.blob()
          download(blob, `${row.contractType.name}-${row.specialist.firstName}-${row.specialist.lastName}`)
        }
    }

    const handleArchived = async (row) => {
        const updatedContract = await updateContract(row.uuid, {
            archived: !row.archived
        })
        if (updatedContract && updatedContract.uuid) {
            setText(`le contrat a été mis à jour`)
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            setText(`le contrat n'a pas été mis a jour`)
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    const handleDelete = () => {
        const deletedContract = deleteContract(selected.uuid)
        if (deletedContract) {
            setText(`le contrat a été supprimé`)
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            setText(`le contrat n'a pas été mis a jour`)
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setSelected(false)
        setOpenDeletePopup(false)
    }

    return (
        <div>
            {contracts ? 
            <Zoom in={zoom}>
                <Paper style={{boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)'}}>
                    <EnhancedTableToolbar handleDelete={handleConfirmationDelete} handleOpenAddPopup={handleOpenAddPopup} />
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                rowCount={contracts.length}
                                darkState={darkState}
                            />
                            <TableBody>
                                {contracts
                                    /* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */
                                    .map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                <TableCell component="th" id={labelId} scope="row" padding="normal">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" padding="normal">
                                                    {row.contractType.name}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" padding="normal">
                                                    {moment(row.startDate).format('DD MMMM YYYY')}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" padding="normal">
                                                    {moment(row.endDate).format('DD MMMM YYYY')}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="normal" style={{textAlign: 'end'}}>
                                                    {row.archived ? <CheckIcon style={{color: 'green'}}/> : <CloseIcon style={{color: 'red'}}/>}
                                                </TableCell>
                                                {isGestionPsy() && (
                                                    <TableCell component="th" scope="row" padding="normal" style={{textAlign: 'end'}}>
                                                        <ButtonGroup variant="contained" color={darkState ? 'secondary' : "primary"}>
                                                            <Tooltip TransitionComponent={Zoom} title="Télécharger">
                                                                <IconButton color={darkState ? 'secondary' : "primary"} onClick={() => handleDownload(row)}>
                                                                    <GetAppIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip TransitionComponent={Zoom} title={row.archived ? 'Désarchiver' : "Archiver"}>
                                                                <IconButton color={darkState ? 'secondary' : "primary"} onClick={() => handleArchived(row)}>
                                                                    {row.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip TransitionComponent={Zoom} title={'Supprimer'}>
                                                                <IconButton color={darkState ? 'secondary' : "primary"} onClick={() => handleConfirmationDelete(row)}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ButtonGroup>
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        );
                                })}
                                {contracts.length === 0 && (
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>Pas de contrats...</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={totalItems}
                            nextIconButtonProps={{ disabled: loadingPage || page >= (totalItems / 30) - 1}}
                            backIconButtonProps={{ disabled: loadingPage || page === 0 }} 
                            rowsPerPage={30}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                        </ThemeProvider>
                    <AddContract
                        error={error}
                        handleChangeContractTypes={handleChangeContractTypes}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setName={setName}
                        loading={loading}
                        darkState={darkState}
                        contractTypes={contractTypes}
                        setContractTypes={setContractTypes}
                        contractType={contractType}
                        setContractType={setContractType}
                        open={openAddPopup}
                        handleClose={handleOpenAddPopup}
                        handleAddContract={handleAddContract}
                    />
                    {loadingPage && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
                    <DeletedConfirmationPopup
                        handleAccept={handleDelete}
                        open={openDeletePopup}
                        handleClose={handleConfirmationDelete}
                        darkState={darkState}
                    />
                </Paper>
            </Zoom>:
            <div style={{textAlign: 'center'}}>
                <CircularProgress color={darkState ? 'secondary' : 'primary'} />
            </div>}
        </div>
    );
}
