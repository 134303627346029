import React, { useState } from 'react'
import { Card, CardHeader, CardContent, IconButton, Typography, Menu, MenuItem, Tooltip, Zoom} from '@mui/material/';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeletedConfirmationPopup from '../../../components/DeletedConfirmationPopup';
import { useNavigate } from 'react-router-dom';
import {isGestionPsy} from '../../../service/security/hasRoles';
import SyncAltIcon from '@mui/icons-material/SyncAlt';

export default function OfficeSpecialistCard({
    officeSpecialist,
    handleOfficeSpecialistDelete,
    darkState,
}) {
    const [loading, setLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [deletePopupOpen, setDeletePopupOpen] = useState(false);
    let navigate = useNavigate()


    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteOfficeSpecialist = () => {
        handleOfficeSpecialistDelete(officeSpecialist)
        setAnchorEl(null)
        setDeletePopupOpen(false)
    }

    const handleCloseConfirmationPopup = () => {
        setDeletePopupOpen(false)
    }

    const handleEditOfficeSpecialist = () => {
        navigate(`/office_specialists/${officeSpecialist.uuid}/edit`)
    }

    const handleConvertSpecialist = () => {
        navigate(`/specialists/${officeSpecialist.uuid}/convert`)
    }

    return (
        <Card>
            <CardHeader
                action={
                    <>
                       <Tooltip TransitionComponent={Zoom} title={'Convertir en psychologue prestataire'}>
                            <IconButton color='inherit' aria-label="share" disabled={loading} onClick={handleConvertSpecialist}>
                                <SyncAltIcon />
                            </IconButton>
                       </Tooltip>
                        {isGestionPsy() && <><IconButton color='inherit' aria-label="settings" onClick={handleMoreClick}>
                            <MoreVertIcon />
                        </IconButton>
                            <Menu
                                disabled={loading}
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleEditOfficeSpecialist}>Modifier</MenuItem>
                                <MenuItem onClick={() => setDeletePopupOpen(true)}>Supprimer</MenuItem>
                            </Menu></>}
                    </>
                }
                title={
                    <a href={`/office_specialists/${officeSpecialist.uuid}`}  style={{textDecoration: 'none', color: darkState ? 'white' : "black"}}>
                        <Typography component="h2">
                            {officeSpecialist.lastName || officeSpecialist.firstName
                                ? `${officeSpecialist.lastName.toUpperCase()} ${officeSpecialist.firstName.charAt(0).toUpperCase() + officeSpecialist.firstName.slice(1).toLowerCase()}`
                                : 'Non renseigné'}
                        </Typography>
                    </a>
                }
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p" style={{marginBottom: 5}}>
                    <span>Email : </span>{`${officeSpecialist.email}` || 'Non renseigné'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" style={{marginBottom: 5}}>
                    <span>Spécialisation : </span>{`${officeSpecialist.specialistType}` || 'Non renseigné'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" style={{marginBottom: 5}}>
                    <span>Public(s) ciblé(s) : </span>{officeSpecialist.targetAudiences && officeSpecialist.targetAudiences.length
                    ? officeSpecialist.targetAudiences.join(' / ')
                    : 'Non renseigné'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" style={{marginBottom: 5}}>
                    <span>N° mobile pro : </span>{officeSpecialist.mobilePro || 'Non renseigné'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" style={{marginBottom: 5}}>
                    <span>Adresse URSSAF : </span>{`${officeSpecialist.urssafAddress}` || 'Non renseigné'}
                </Typography>
            </CardContent>
            <DeletedConfirmationPopup
                disabled={loading}
                handleAccept={handleDeleteOfficeSpecialist}
                open={deletePopupOpen}
                handleClose={handleCloseConfirmationPopup}
                darkState={darkState}
            />
        </Card>
    )
}