import React from 'react'
import { 
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    Zoom,
    Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {hasOneOfRoles} from '../../service/security/hasRoles';

export const EnhancedTableToolbar = ({ handleNew, darkState, title, disableAddIcon, handlePurged, allowedRoles }) => {
    const isAllowed = hasOneOfRoles(allowedRoles)
  
    return (
        <Toolbar>
            <Typography color={darkState ? 'secondary' : 'primary'} variant="h5" id="tableTitle" component="div" sx={{flex: '1 1 100%'}}>
                {title || 'Entreprises'}
            </Typography>
            {isAllowed && !disableAddIcon && <Tooltip TransitionComponent={Zoom} title={`Ajouter une ${title.toLowerCase()}`}>
                <IconButton color='inherit' aria-label="add company" onClick={handleNew}>
                    <AddIcon />
                </IconButton>
            </Tooltip>}
            {isAllowed && handlePurged && <Button color={darkState ? 'secondary' : 'primary'} variant='contained' onClick={handlePurged}>
                Archive
            </Button>}
        </Toolbar>
    );
};