import {
    Grid,
    TextField,
    Paper,
} from '@mui/material';
import MultipleCheckBoxes from '../../components/MultipleCheckboxes';
import MultipleAddCheckBoxes from '../../components/MultipleAddCheckboxes';

export default function Skills({
    error,
    darkState,
    descriptionCvHeader,
    setDescriptionCvHeader,
    specialistEdit,
    status,
    setStatus,
    administrativeTypes,
    specialistType,
    setSpecialistType,
    specialistTypes,
    language,
    setLanguage,
    languages,
    setText,
    setTransition,
    setOpenSnackbar,
    setSeverity,
    setExpertise,
    expertises,
    expertise,
    createExpertise,
    certification,
    certifications,
    setCertification,
    createCertification,
    experience,
    experiences,
    setExperience,
    createExperience,
    activities,
    activity,
    setActivity,
    comment,
    setComment,
    multipleCompanies,
    setMultipleCompanies,
    createCompany,
    companies,
    speciality,
    setSpeciality,
    specialities,
    createSpeciality,
    convertActivitiesToArray,
}) {
    return (
        <Paper sx={{mt: 3, pl: 3}}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        value={descriptionCvHeader}
                        onChange={(event) => {
                          setDescriptionCvHeader(event.target.value);
                        }} 
                        color={darkState ? 'secondary' : "primary"} 
                        multiline 
                        rows={5} 
                        error={!!error.descriptionCvHeader} 
                        helperText={error.descriptionCvHeader}
                        id="descriptionCvHeader"
                        name="descriptionCvHeader"
                        label="Présentation du psychologue"
                        sx={{
                            width: 400,
                            mb: 3
                        }}
                    /><br />
                    {administrativeTypes && <MultipleCheckBoxes
                        darkState={darkState}
                        defaultValue={specialistEdit.administrativeTypes}
                        data={status}
                        setData={setStatus}
                        id='administrativeTypes'
                        allOptions={administrativeTypes}
                        error={error}
                        label='Type de réseau'
                        placeholder='Vous pouvez sélectionner une ou plusieurs statut'
                    />}
                    {specialistTypes && <MultipleCheckBoxes
                        isCv={true}
                        darkState={darkState}
                        defaultValue={specialistEdit.specialistType}
                        data={specialistType}
                        error={error}
                        setData={setSpecialistType}
                        id='specialistType'
                        allOptions={specialistTypes}
                        label='Type de spécialistes'
                        placeholder='Vous pouvez sélectionner une ou plusieurs types' 
                    />}
                    {languages && <MultipleCheckBoxes
                        defaultValue={specialistEdit.languages}
                        data={language}
                        setData={setLanguage}
                        darkState={darkState}
                        id='languages'
                        error={error}
                        allOptions={languages}
                        label='Langues' 
                        placeholder='Vous pouvez sélectionner une ou plusieurs langues'
                    />}
                    {expertises && <MultipleAddCheckBoxes
                        isCv={true}
                        darkState={darkState}
                        text={'expertise'}
                        label={'Expertises'}
                        setOpenSnackbar={setOpenSnackbar}
                        setText={setText}
                        setTransition={setTransition}
                        setSeverity={setSeverity}
                        error={error}
                        selectedData={expertise}
                        setData={setExpertise}
                        id='expertises'
                        createData={createExpertise}
                        data={expertises}
                    />}
                    {certifications && <MultipleAddCheckBoxes
                        isCv={true}
                        darkState={darkState}
                        text={'certification'}
                        label={'Formations/Certifications'}
                        setOpenSnackbar={setOpenSnackbar}
                        setText={setText}
                        setTransition={setTransition}
                        setSeverity={setSeverity}
                        selectedData={certification}
                        error={error}
                        setData={setCertification}
                        id='certifications'
                        createData={createCertification}
                        data={certifications}
                    />}
                    {experiences && <MultipleAddCheckBoxes
                        uuid={specialistEdit.uuid}
                        isCv={true}
                        error={error}
                        darkState={darkState}
                        text={'expérience'}
                        label={'Expériences professionnelles'}
                        setOpenSnackbar={setOpenSnackbar}
                        setText={setText}
                        setTransition={setTransition}
                        setSeverity={setSeverity}
                        selectedData={experience}
                        setData={setExperience}
                        id='experiences'
                        createData={createExperience}
                        data={experiences}
                    />}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {activities && <MultipleCheckBoxes
                        isCv={true}
                        defaultValue={specialistEdit.activity ? convertActivitiesToArray(specialistEdit.activity) : []}
                        data={activity}
                        setData={setActivity}
                        darkState={darkState}
                        error={error}
                        id='activities'
                        allOptions={activities}
                        label='Activités'
                        placeholder='Vous pouvez sélectionner une ou plusieurs activités'
                    />}
                    {companies && <MultipleAddCheckBoxes 
                        isCv={true}
                        darkState={darkState}
                        text={'référence'}
                        error={error}
                        label={'Références'}
                        setOpenSnackbar={setOpenSnackbar}
                        setText={setText}
                        setTransition={setTransition}
                        setSeverity={setSeverity}
                        selectedData={multipleCompanies}
                        setData={setMultipleCompanies}
                        id='references'
                        createData={createCompany}
                        data={companies}
                    />}
                    {specialities && <MultipleAddCheckBoxes
                        darkState={darkState}
                        text={'spécialité'}
                        label={'Spécialités'}
                        error={error}
                        setOpenSnackbar={setOpenSnackbar}
                        setText={setText}
                        setTransition={setTransition}
                        setSeverity={setSeverity}
                        selectedData={speciality}
                        setData={setSpeciality}
                        id='specialities'
                        createData={createSpeciality}
                        data={specialities}
                    />}
                    <TextField
                        id="comment"
                        label="Commentaire"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        multiline
                        rows={5}
                        color={darkState ? 'secondary' : 'primary'}
                        style={{width: 400, marginBottom: 30}}
                        error={!!error.comment}
                        helperText={error.comment}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}