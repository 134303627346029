import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
    Chip,
    LinearProgress,
    Button,
    Accordion,
    Typography,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Tooltip,
    Zoom,
    Grid,
    FormControlLabel,
    Switch
} from '@mui/material/';
import MultipleCheckBoxes from './MultipleCheckboxes';
import { apiFetch } from '../service/apiFetch';
import { formatMultipleOptions, removeLastAmpersand, TransitionLeft } from '../helpers/utils';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { isGestionPsy, isAppelOffreOrCommercialOrProjetOrGestionPsy } from '../service/security/hasRoles';
import AutoCompleteAddress from './AutoCompleteAddress';
import DescriptionIcon from '@mui/icons-material/Description';
import SubjectIcon from '@mui/icons-material/Subject';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

// Composant FilterList mémorisé
const FilterList = React.memo(({
    setIsAdministrativeTypeDeleted,
    setActivity,
    hasFilter,
    setRefreshMultiple,
    setDisableFilterList,
    disableFilterList,
    setUpdateFilter,
    darkState,
    filterList,
    state,
    setState,
    setLanguage,
    setSpeciality,
    setAdministrativeType,
    setCertification,
    setSpecialistType,
    setFirstName,
    lastName,
    setLastName,
    setNewCoordonnate
}) => {

    const findRightFunction = useCallback((
        key,
        setActivity,
        setLanguage,
        setSpeciality,
        setAdministrativeType,
        setCertification,
        setSpecialistType,
        setFirstName,
        setLastName,
        setIsAdministrativeTypeDeleted
    ) => {
        switch(key['@type']) {
            case 'AdministrativeType':
                setIsAdministrativeTypeDeleted(true);
                return setAdministrativeType;
            case 'Language':
                return setLanguage;
            case 'Speciality':
                return setSpeciality;
            case 'Certification':
                return setCertification;
            case "SpecialistType": 
                return setSpecialistType;
            case 'firstName':
                return setFirstName;
            case 'lastName':
                return setLastName;
            case 'Activity':
                return setActivity;
            case 'ville':
                return setNewCoordonnate;
            default:
                return false;
        }
    }, [setNewCoordonnate]);


    const handleRemoveFilter = useCallback((e, list, item) => {
        if (disableFilterList) return;
        setDisableFilterList(true);
        const func = findRightFunction(
            item,
            setActivity,
            setLanguage,
            setSpeciality,
            setAdministrativeType,
            setCertification,
            setSpecialistType,
            setFirstName,
            setLastName,
            setIsAdministrativeTypeDeleted,
            setNewCoordonnate
        );
        if (list.length < 2) {
            func([]);
        } else if (typeof item === 'string') {
            setState({ ...state, [item]: false });
        } else {
            const remove = list.filter((i) => i.id !== item.id);
            func(remove);
        }
        setRefreshMultiple(true);
        setUpdateFilter(true);
    }, [disableFilterList, setDisableFilterList, findRightFunction, setActivity, setLanguage, setSpeciality, setAdministrativeType, setCertification, setSpecialistType, setFirstName, setLastName, setIsAdministrativeTypeDeleted, setNewCoordonnate, setState, state, setRefreshMultiple, setUpdateFilter]);



    return (
        <>
            {hasFilter() && (
                <>
                    <Typography style={{ width: 225, marginTop: 15 }}>Filtre(s) en cours : </Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap', flex: '1 1 100%', marginTop: 15 }}>
                        {filterList && !!filterList.length && filterList.map((list, i) => (
                            <React.Fragment key={i}>
                                {list && list.length ? list.map((item, index) => (
                                    item && item.name && (
                                        <div key={index} style={{ display: 'flex', marginRight: 10, marginBottom: 10 }}>
                                            <Chip
                                                size="small"
                                                label={item.name}
                                                onDelete={(e) => handleRemoveFilter(e, list, item)}
                                                color={darkState ? 'secondary' : 'primary'}
                                            />
                                        </div>
                                    )
                                )) :
                                typeof list === 'object' && Object.keys(list).map((s, i) => (
                                    list[s] && (
                                        <div key={i} style={{ display: 'flex', marginRight: 10, marginBottom: 10 }}>
                                            <Chip
                                                size="small"
                                                label={s}
                                                onDelete={(e) => handleRemoveFilter(e, list, s)}
                                                color={darkState ? 'secondary' : 'primary'}
                                            />
                                        </div>
                                    )
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                </>
            )}
        </>
    );
});

// Composant principal
const SpecialistFilters = ({
    isFromMap,
    setNewCoordonnate,
    newCoordonnate,
    isFromList,
    setIsFiltered,
    activity,
    setActivity,
    activities,
    foundAdministrativeType,
    setAllSpecialists,
    handleDisplayTable,
    handleOpenExportSpecialist,
    handleExportSpecialistFilter,
    handleOpenExportSpecialistCv,
    handleOpenExportSpecialistDocument,
    filterList,
    setFilterList,
    setNoSpecialist,
    state,
    setState,
    language,
    setLanguage,
    languages,
    setLanguages,
    speciality,
    setSpeciality,
    specialities,
    setSpecialities,
    administrativeType,
    setAdministrativeType,
    administrativeTypes,
    setAdministrativeTypes,
    certification,
    setCertification,
    certifications,
    setCertifications,
    specialistType,
    setSpecialistType,
    specialistTypes,
    setSpecialistTypes,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneResponsive,
    fetchSpecialists,
    handleAddSpecialist,
    isReady,
    setDisplayTable,
    displayTable,
    setRefresh,
    setSpecialists,
    firstFilter,
    darkState,
    expanded,
    setExpanded,
    zoom,
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity
}) => {
    const [disabledFilter, setDisabledFilter] = useState(false);
    const [refreshMultiple, setRefreshMultiple] = useState(false);
    const [disableFilterList, setDisableFilterList] = useState(false);
    const [isAdministrativeTypeDeleted, setIsAdministrativeTypeDeleted] = useState(false);
    const [updateFilter, setUpdateFilter] = useState(false);

    const handleChange = useCallback((event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    }, [setState, state]);

    useEffect(() => {
        (async () => {
            if (filterList) {
                await handleFilter()
            }

            const specialitiesFetch = await apiFetch('/specialities?pagination=false')
            if (specialitiesFetch && specialitiesFetch['hydra:member']) {
                setSpecialities(specialitiesFetch['hydra:member'])
            } else {
                setText((specialitiesFetch && specialitiesFetch['hydra:description']) || 'Erreur lors de la récupération des Spécialités cliniques')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }

            const languagesFetch = await apiFetch('/languages')
            if (languagesFetch && languagesFetch['hydra:member']) {
                setLanguages(languagesFetch['hydra:member'])
            } else {
                setText((languagesFetch && languagesFetch['hydra:description']) || 'Erreur lors de la récupération des langues')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }

            const certificationsFetch = await apiFetch('/certifications?pagination=false')
            if (certificationsFetch && certificationsFetch['hydra:member']) {
                setCertifications(certificationsFetch['hydra:member'])
            } else {
                setText((certificationsFetch && certificationsFetch['hydra:description']) || 'Erreur lors de la récupération des types')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }
            const specialistTypes = await apiFetch('/specialist_types')
            if (specialistTypes && specialistTypes['hydra:member']) {
                setSpecialistTypes(specialistTypes['hydra:member'])
            } else {
                setText((specialistTypes && specialistTypes['hydra:description']) || 'Erreur lors de la récupération des types')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }
        })()
    }, [setOpenSnackbar, setText, setTransition, setSeverity, setAdministrativeTypes, setCertifications, setLanguages, setSpecialities, setSpecialistTypes]);

    const handleFilter = useCallback(async () => {
        setDisabledFilter(true);
        setUpdateFilter(false);
        const chosenSpecialities = speciality ? formatMultipleOptions('specialities.name', speciality) : '';
        const foundFirstName = firstName ? `firstName=${firstName}&` : '';
        const foundLastName = lastName ? `lastName=${lastName}&` : '';
        const foundCity = newCoordonnate ? `distance_from=${newCoordonnate.lat},${newCoordonnate.lng}&` : '';
        const alreadyFiltered = firstFilter ? `${firstFilter}&` : '';
        const languagesFilter = language ? formatMultipleOptions('languages.name', language) : '';
        const administrativeTypeFilter = administrativeType && !isFromList ?
            formatMultipleOptions('administrativeTypes.name', administrativeType) : isFromMap && !isAdministrativeTypeDeleted ? `administrativeTypes.name[]=Pros-Consulte&` : '';
        const specialistTypeFilter = specialistType ? formatMultipleOptions('specialistType.name',specialistType) : '';
        const certificationFilter = certification ? formatMultipleOptions('certifications.name',certification) : '';
        const officeFilter = state.office ? `exists[offices]&` : '';
        const iprpFilter = state.iprp ? `iprp=true&` : '';
        const pssmFilter = state.pssm ? `pssm=true&` : '';
        const homeInterventionFilter = activity && activity.length &&
            !!activity.find((item) => item.id === 'homeIntervention') ? `activity.homeInterventions=true&` : '';
        const appFilter = activity && activity.length &&
            !!activity.find((item) => item.id === 'app') ? `activity.app=true&` : '';
        const auditFilter = activity && activity.length &&
            !!activity.find((item) => item.id === 'audit') ? `activity.audit=true&` : '';
        const coachingFilter = activity && activity.length &&
            !!activity.find((item) => item.id === 'coaching') ? `activity.coaching=true&` : '';
        const conflictResolutionSupportFilter = activity && activity.length &&
            !!activity.find((item) => item.id === 'conflictResolutionSupport') ? `activity.conflictResolutionSupport=true&` : '';
        const crisisUnitFilter = activity && activity.length &&
            !!activity.find((item) => item.id === 'crisisUnit') ? `activity.crisisUnit=true&` : '';
        const harassmentInvestigationFilter = activity && activity.length &&
            !!activity.find((item) => item.id === 'harassmentInvestigation') ? `activity.harassmentInvestigation=true&` : '';
        const certifiedCoachingFilter = activity && activity.length &&
            !!activity.find((item) => item.id === 'certifiedCoaching') ? `activity.certifiedCoaching=true&` : '';
        const platformFilter = activity && activity.length &&
            !!activity.find((item) => item.id === 'platform') ? `activity.platform=true&` : '';
        const projectManagerFilter = activity && activity.length &&
            !!activity.find((item) => item.id === 'projectManager') ? `activity.projectManager=true&` : '';
        const rpsCoachFilter = activity && activity.length &&
            !!activity.find((item) => item.id === 'rpsCoach') ? `activity.rpsCoach=true&` : '';
        const pseFilter = activity && activity.length &&
            !!activity.find((item) => item.id === 'pse') ? `activity.pse=true&` : '';
        const gpVisioFilter = state.gpVisio ? `activity.visio=${state.gpVisio}&` : '';
        const gpAudioFilter = state.gpAudio ? `activity.call=${state.gpAudio}&` : '';
        const url = removeLastAmpersand(`/specialists?${foundAdministrativeType ? `administrativeTypes.name[]=${foundAdministrativeType()}&` : ''}${alreadyFiltered}${officeFilter}${iprpFilter}${pssmFilter}${homeInterventionFilter}${appFilter}${auditFilter}${coachingFilter}${conflictResolutionSupportFilter}${crisisUnitFilter}${harassmentInvestigationFilter}${certifiedCoachingFilter}${platformFilter}${projectManagerFilter}${pseFilter}${gpAudioFilter}${gpVisioFilter}${foundFirstName}${languagesFilter}${administrativeTypeFilter}${specialistTypeFilter}${certificationFilter}${foundLastName}${foundCity}${rpsCoachFilter}${chosenSpecialities}pagination=false`);

        setFilterList([
            speciality,
            [{'@type': 'firstName', name: firstName}],
            [{'@type': 'lastName', name: lastName}],
            language,
            administrativeType,
            specialistType,
            certification,
            state,
            activity,
            newCoordonnate ? [{'@type': 'ville', name: 'Ville'}] : false
        ]);
        setRefresh && setRefresh(true);
        const filtered = fetchSpecialists ? await fetchSpecialists(url) : await apiFetch(url);
        if (filtered) {
            if (filtered.length === 0) setNoSpecialist && setNoSpecialist(true);
            setIsFiltered && setIsFiltered(true);
            setSpecialists && setSpecialists(filtered);
            setAllSpecialists && setAllSpecialists(filtered);
            setRefreshMultiple(false);
        } else {
            setText((specialistTypes && specialistTypes['hydra:description']) || 'Erreur lors de la filtration des données');
            setTransition(() => TransitionLeft);
            setSeverity('error');
            setOpenSnackbar(true);
        }
        setRefresh && setRefresh(false);
        setExpanded(false);
        setDisabledFilter(false);
        setDisableFilterList(false);
    }, [speciality, firstName, lastName, specialistTypes, newCoordonnate, firstFilter, language, administrativeType, isFromList, isFromMap, isAdministrativeTypeDeleted, specialistType, certification, state, activity, foundAdministrativeType, setFilterList, setRefresh, fetchSpecialists, setNoSpecialist, setIsFiltered, setSpecialists, setAllSpecialists, setText, setTransition, setSeverity, setOpenSnackbar, setExpanded, setDisabledFilter, setDisableFilterList, setRefreshMultiple]);

    const handleDeleteFilter = useCallback(async () => {
        setDisabledFilter(true);
        const deleteFiltered = fetchSpecialists ?
            await fetchSpecialists(`/specialists?pagination=false&${foundAdministrativeType ? `administrativeTypes.name[]=${foundAdministrativeType()}` : ''}`) :
            await apiFetch(`/specialists?pagination=false&${foundAdministrativeType ? `administrativeTypes.name[]=${foundAdministrativeType()}` : ''}`);

        if (deleteFiltered && (deleteFiltered.length || deleteFiltered['hydra:member'])) {
            setSpeciality([]);
            setLanguage([]);
            setAdministrativeType([]);
            setSpecialistType([]);
            setCertification([]);
            setDisabledFilter(true);
            setState({
                office: false,
                iprp: false,
                pssm: false,
                motorized: false,
                homeIntervention: false,
                platform: false,
                crisisUnit: false,
                conflictResolutionSupport: false,
                pse: false,
                app: false,
                coaching: false,
                certifiedCoaching: false,
                audit: false,
                projectManager: false,
                harassmentInvestigation: false,
                rpsCoach: false,
                gpAudio: false,
                gpVisio: false,
            });
            setFilterList && setFilterList(false);
            setSpecialists && setSpecialists(deleteFiltered);
            setAllSpecialists && setAllSpecialists(deleteFiltered);
            setRefreshMultiple(true);
        } else {
            setText((specialistTypes && specialistTypes['hydra:description']) || 'Erreur lors de la récupération des données');
            setTransition(() => TransitionLeft);
            setSeverity('error');
            setOpenSnackbar(true);
        }
        setRefreshMultiple(false);
        setRefresh && setRefresh(false);
        setExpanded(!expanded);
        setDisabledFilter(false);
    }, [fetchSpecialists, foundAdministrativeType, setSpeciality, setLanguage, specialistTypes, setAdministrativeType, setSpecialistType, setCertification, setDisabledFilter, setState, setFilterList, setSpecialists, setAllSpecialists, setText, setTransition, setSeverity, setOpenSnackbar, setRefreshMultiple, setRefresh, expanded, setExpanded]);

    const hasFilter = useCallback(() => {
        let hasState = false;
        Object.keys(state).map((s, i) => {
            if (state[s]) {
                hasState = true;
            }
            return null;
        });

        return !!((language && language.length) ||
        firstName ||
        lastName ||
        (specialistType && specialistType.length) ||
        (activity && activity.length) ||
        (speciality && speciality.length) ||
        (administrativeType && administrativeType.length) ||
        (certification && certification.length) ||
        newCoordonnate ||
        hasState);
    }, [language, firstName, lastName, specialistType, activity, speciality, administrativeType, certification, newCoordonnate, state]);

    const accordionStyle = useMemo(() => ({
        marginBottom: 30,
        boxShadow: '0 4px 4px 0 rgb(34 41 47 / 10%)',
        borderRadius: 4,
        maxWidth: phoneResponsive ? 257 : undefined
    }), [phoneResponsive]);

    const renderExportButtons = useMemo(() => (
        <>
            {isAppelOffreOrCommercialOrProjetOrGestionPsy() && handleOpenExportSpecialistCv &&
            <IconButton color='inherit' style={phoneResponsive ? {marginRight: -15} : {}} onClick={handleOpenExportSpecialistCv}>
                <Tooltip TransitionComponent={Zoom} title={`Exporter des Cvs`}>
                    <DescriptionIcon />
                </Tooltip>
            </IconButton>}
            {isAppelOffreOrCommercialOrProjetOrGestionPsy() && handleOpenExportSpecialistDocument &&
            <IconButton color='inherit' style={phoneResponsive ? {marginRight: -15} : {}} onClick={handleOpenExportSpecialistDocument}>
                <Tooltip TransitionComponent={Zoom} title={`Exporter des documents`}>
                    <SubjectIcon />
                </Tooltip>
            </IconButton>}
        </>
    ), [handleOpenExportSpecialistCv, handleOpenExportSpecialistDocument, phoneResponsive]);

    return (
        <Zoom in={zoom} style={{ transitionDelay: '1000ms' }}>
            <Accordion style={accordionStyle} expanded={expanded}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" style={{cursor: 'default'}}>
                    {updateFilter && handleFilter()}
                    <Typography
                        style={expanded || !hasFilter() ? {flex: '1 1 100%',} : {}}
                        color={darkState ? 'secondary' : 'primary'}
                        sx={{
                            alignItems: 'center',
                            fontSize: 24,
                            mt: 1,
                            mr: {sm: 10, xs: 2},
                        }}
                    >
                        Filtres
                    </Typography>
                    {!expanded && !!administrativeTypes.length && <FilterList
                        setNewCoordonnate={setNewCoordonnate}
                        setIsAdministrativeTypeDeleted={setIsAdministrativeTypeDeleted}
                        setActivity={setActivity}
                        hasFilter={hasFilter}
                        setRefreshMultiple={setRefreshMultiple}
                        disableFilterList={disableFilterList}
                        setUpdateFilter={setUpdateFilter}
                        setDisableFilterList={setDisableFilterList}
                        handleFilter={handleFilter}
                        setFilterList={setFilterList}
                        state={state}
                        setState={setState}
                        language={language}
                        setLanguage={setLanguage}
                        languages={languages}
                        setLanguages={setLanguages}
                        speciality={speciality}
                        setSpeciality={setSpeciality}
                        specialities={specialities}
                        setSpecialities={setSpecialities}
                        administrativeType={administrativeType}
                        setAdministrativeType={setAdministrativeType}
                        administrativeTypes={administrativeTypes}
                        setAdministrativeTypes={setAdministrativeTypes}
                        certification={certification}
                        setCertification={setCertification}
                        certifications={certifications}
                        setCertifications={setCertifications}
                        specialistType={specialistType}
                        setSpecialistType={setSpecialistType}
                        specialistTypes={specialistTypes}
                        setSpecialistTypes={setSpecialistTypes}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        darkState={darkState}
                        filterList={filterList}
                    />}
                    {renderExportButtons}
                    {handleAddSpecialist && isGestionPsy() &&
                    <IconButton color='inherit' style={phoneResponsive ? {marginRight: -15} : {}} onClick={handleAddSpecialist}>
                        <Tooltip TransitionComponent={Zoom} title={`Ajouter un spécialiste`}>
                           <AddIcon />
                        </Tooltip>
                    </IconButton>}
                    {setDisplayTable &&
                     <IconButton color='inherit' style={phoneResponsive ? {marginRight: -15} : {}} disabled={!isReady} onClick={handleDisplayTable}>
                        <Tooltip TransitionComponent={Zoom} title={`${displayTable ? 'Mode carte' : 'Mode tableau'}`}>
                            {displayTable ? <ViewListIcon /> : <ViewModuleIcon />}
                        </Tooltip>
                    </IconButton>}
                    {isGestionPsy() && handleExportSpecialistFilter && <IconButton style={phoneResponsive ? {marginRight: -15} : {}} onClick={handleExportSpecialistFilter}>
                        <Tooltip TransitionComponent={Zoom} title={`Exporter les spécialistes filtrés`}>
                            <CloudDownloadIcon />
                        </Tooltip>
                    </IconButton>}
                    <IconButton color='inherit' style={phoneResponsive ? {marginRight: -15} : {}} onClick={() => setExpanded(!expanded)}>
                        <Tooltip TransitionComponent={Zoom} title={'Liste des filtres'}>
                            <FilterListIcon />
                        </Tooltip>
                    </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <Typography color={darkState ? 'secondary' : 'primary'}>Données communes</Typography>
                            <div style={{marginTop: 0, marginBottom: 40}}>
                                {isFromMap && <AutoCompleteAddress
                                    setRefresh={setRefresh}
                                    setExpanded={setExpanded}
                                    darkState={darkState}
                                    id="city"
                                    name="city"
                                    label="Ville"
                                    defaultValue={''}
                                    setNewCoordonnate={setNewCoordonnate}
                                /> }
                            </div>
                            {isFromList && <div style={{marginTop: -40, marginBottom: 40}}>
                                <AutoCompleteAddress
                                    setRefresh={setRefresh}
                                    darkState={darkState}
                                    id="city"
                                    name="city"
                                    label="Ville"
                                    defaultValue={''}
                                    setNewCoordonnate={setNewCoordonnate}
                                />
                            </div>}
                            {languages && !!languages.length && !refreshMultiple && <MultipleCheckBoxes
                                defaultValue={filterList && filterList[3] ? filterList[3] : []}
                                phoneResponsive={phoneResponsive}
                                data={language}
                                setData={setLanguage}
                                darkState={darkState}
                                id='languages'
                                allOptions={languages}
                                label='Langues'
                                placeholder='Vous pouvez sélectionner une ou plusieurs langues'
                            />}
                            {administrativeTypes && !!administrativeTypes.length && !refreshMultiple && !setAllSpecialists && <MultipleCheckBoxes
                                defaultValue={
                                    filterList && filterList[4] ?
                                        filterList[4] :
                                        isFromMap && !isAdministrativeTypeDeleted ?
                                            [administrativeTypes.find((item) => item.name === 'Pros-Consulte')] :
                                            []
                                }
                                phoneResponsive={phoneResponsive}
                                data={administrativeType}
                                setData={setAdministrativeType}
                                darkState={darkState}
                                id='administrativeTypes'
                                allOptions={administrativeTypes}
                                label='Type'
                                placeholder='Vous pouvez sélectionner une ou plusieurs types'
                            />}
                            {specialities && !!specialities.length && !refreshMultiple && <MultipleCheckBoxes
                                defaultValue={filterList && filterList[0] ? filterList[0] : []}
                                phoneResponsive={phoneResponsive}
                                data={speciality}
                                setData={setSpeciality}
                                darkState={darkState}
                                id='specialities'
                                allOptions={specialities}
                                label='Spécialités cliniques'
                                placeholder='Vous pouvez sélectionner une ou plusieurs Spécialités cliniques'
                            />}
                            {specialistTypes && !!specialistTypes.length && !refreshMultiple && <MultipleCheckBoxes
                                defaultValue={filterList && filterList[5] ? filterList[5] : []}
                                phoneResponsive={phoneResponsive}
                                data={specialistType}
                                setData={setSpecialistType}
                                darkState={darkState}
                                id='specialistTypes'
                                allOptions={specialistTypes}
                                label='Type de spécialistes'
                                placeholder='Vous pouvez sélectionner une ou plusieurs types'
                            />}
                            <Grid container spacing={3} style={ phoneResponsive ? {marginTop: 30} : {marginTop: 30}}>
                                <Grid item xs={phoneResponsive ? 7 : 5} >
                                    <Typography style={{margin: 0, color: darkState ? '#bbb' : '#676'}}>Cabinet : </Typography>
                                </Grid>
                                <Grid item xs={phoneResponsive ? 5 : 6} style={{paddingTop: 5}}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={state.office}
                                                onChange={handleChange}
                                                name="office"
                                                id="office"
                                                color={ darkState ? 'secondary' :"primary"}
                                            />
                                        }
                                        style={{margin: 0, color: darkState ? '#bbb' : '#676', marginBottom: 20}}
                                    /><br />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} style={ phoneResponsive ? {marginTop: 30} : {marginTop: 10}}>
                                <Grid item xs={phoneResponsive ? 7 : 5} >
                                    <Typography style={{margin: 0, color: darkState ? '#bbb' : '#676'}}>IPRP : </Typography>
                                </Grid>
                                <Grid item xs={phoneResponsive ? 5 : 6} style={{paddingTop: 5}}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={state.iprp}
                                                onChange={handleChange}
                                                name="iprp"
                                                id="iprp"
                                                color={ darkState ? 'secondary' :"primary"}
                                            />
                                        }
                                        style={{margin: 0, color: darkState ? '#bbb' : '#676', marginBottom: 20}}
                                    /><br />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} style={ phoneResponsive ? {marginTop: 30} : {marginTop: 10}}>
                                <Grid item xs={phoneResponsive ? 7 : 5} >
                                    <Typography style={{margin: 0, color: darkState ? '#bbb' : '#676'}}>PSSM : </Typography>
                                </Grid>
                                <Grid item xs={phoneResponsive ? 5 : 6} style={{paddingTop: 5}}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={state.pssm}
                                                onChange={handleChange}
                                                name="pssm"
                                                id="pssm"
                                                color={ darkState ? 'secondary' :"primary"}
                                            />
                                        }
                                        style={{margin: 0, color: darkState ? '#bbb' : '#676', marginBottom: 20}}
                                    /><br />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography color={darkState ? 'secondary' : 'primary'}>Filtres PC</Typography>
                            {certifications && !!certifications.length && !refreshMultiple && <MultipleCheckBoxes
                                defaultValue={filterList && filterList[6] ? filterList[6] : []}
                                phoneResponsive={phoneResponsive}
                                data={certification}
                                setData={setCertification}
                                darkState={darkState}
                                id='certifications'
                                allOptions={certifications}
                                label='Formations/Certifications'
                                placeholder='Vous pouvez sélectionner une ou plusieurs certifications'
                            />}
                            {activities && !!activities.length && !refreshMultiple && <MultipleCheckBoxes
                                defaultValue={filterList && filterList[8] ? filterList[8] : []}
                                phoneResponsive={phoneResponsive}
                                data={activity}
                                setData={setActivity}
                                darkState={darkState}
                                id='activity'
                                allOptions={activities}
                                label='Activités'
                                placeholder='Vous pouvez sélectionner une ou plusieurs activités'
                            />}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography color={darkState ? 'secondary' : 'primary'}>Filtres GP</Typography>
                            <Grid container spacing={3} style={{marginTop: 20}}>
                                <Grid item xs={phoneResponsive ? 7 : 5} >
                                    <Typography style={{margin: 0, color: darkState ? '#bbb' : '#676'}}>Appel : </Typography>
                                </Grid>
                                <Grid item xs={phoneResponsive ? 5 : 6} style={{paddingTop: 5}}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={state.gpAudio}
                                                onChange={handleChange}
                                                name="gpAudio"
                                                id="gpAudio"
                                                color={ darkState ? 'secondary' :"primary"}
                                            />
                                        }
                                        style={{margin: 0, color: darkState ? '#bbb' : '#676', marginBottom: 20}}
                                    /><br />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} style={phoneResponsive ? {marginTop: 0} : {marginTop: 20}}>
                                <Grid item xs={phoneResponsive ? 7 : 5} >
                                    <Typography style={{margin: 0, color: darkState ? '#bbb' : '#676'}}>Visio : </Typography>
                                </Grid>
                                <Grid item xs={phoneResponsive ? 5 : 6} style={{paddingTop: 5}}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={state.gpVisio}
                                                onChange={handleChange}
                                                name="gpVisio"
                                                id="gpVisio"
                                                color={ darkState ? 'secondary' :"primary"}
                                            />
                                        }
                                        style={{margin: 0, color: darkState ? '#bbb' : '#676', marginBottom: 20}}
                                    /><br />
                                </Grid>
                            </Grid>
                        </Grid >
                            {/* Boutons */}
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                              <Button variant="contained" color={darkState ? 'secondary' : 'primary'} disabled={disabledFilter} onClick={handleFilter} sx={{ mx: 1 }}>
                               Filtrer
                              </Button>
                              <Button variant="contained" color={darkState ? 'secondary' : 'primary'} disabled={disabledFilter} onClick={handleDeleteFilter} sx={{ mx: 1 }}>
                                Supprimer les filtres
                              </Button>
                              </Grid>
                    </Grid>
                </AccordionDetails>
                {disabledFilter && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
            </Accordion>
        </Zoom>
    );
};

export default SpecialistFilters;