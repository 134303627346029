import React, { useEffect, useState } from 'react';
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    IconButton,
    Tooltip,
    CircularProgress,
    Zoom,
    createTheme,
    ThemeProvider,
    ButtonGroup,
    Button,
    LinearProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import { download, TransitionLeft } from '../../../helpers/utils';
import GetAppIcon from '@mui/icons-material/GetApp';
import * as locales from '@mui/material/locale';
import moment from 'moment'
import { apiFetch } from '../../../service/apiFetch';
import { useCvs } from '../../../hooks/cvs';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCv from './CVsItem/AddCv';
import { handleFormError } from '../../../service/security/formError';
import DeletedConfirmationPopup from '../../../components/DeletedConfirmationPopup';
import {isAdministrateur} from "../../../service/security/hasRoles";

const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Nom du contrat' },
    { id: 'createAt', numeric: false, disablePadding: false, label: 'Date de création' },
    { id: 'updateAt', numeric: false, disablePadding: true, label: 'Date de mise à jour' },
    ...(isAdministrateur() ? [{ id: 'action', numeric: true, disablePadding: true, label: 'Action' }] : []),
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, darkState } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{fontWeight: 'bold', color: darkState ? '#7fe1dd' : '#12047D' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span
                                    style={{
                                        border: 0,
                                        clip: 'rect(0 0 0 0)',
                                        height: 1,
                                        margin: -1,
                                        overflow: 'hidden',
                                        padding: 0,
                                        position: 'absolute',
                                        top: 20,
                                        width: 1,
                                    }}
                                >
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const { handleOpenAddPopup, darkState, handleDownload } = props;

    return (
        <Toolbar>
           
            <Typography variant="h6" id="tableTitle" component="div" sx={{flex: '1 1 100%'}}>
                CVs
            </Typography>
            {isAdministrateur() && <Button variant={'contained'} size={'small'} color={darkState ? 'secondary' : 'primary'} onClick={() => handleDownload(null, true)}>
                CV original
            </Button>}
            {isAdministrateur() &&
            <Tooltip TransitionComponent={Zoom} title="Ajouter un cv" style={{marginLeft: 20}}>
                <IconButton color='inherit' aria-label="filter list" onClick={handleOpenAddPopup}>
                    <AddIcon />
                </IconButton>
            </Tooltip>}
        </Toolbar>
    );
};

export default function Cvs({ specialist, setOpenSnackbar, setText, setTransition, setSeverity, darkState, zoom }) {
    const [page, setPage] = useState(0);
    const [openAddPopup, setOpenAddPopup] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [locale] = useState('frFR');
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false);
    const { id } = useParams()
    let navigate = useNavigate()

    const {
        cvs,
        totalItems,
        view,
        fetchCvs,
        createCv,
        deleteCv,
    } = useCvs()
    
    useEffect(() => {
        (async () => {
            const cvsFetch = await fetchCvs(id)
            if (cvsFetch && cvsFetch['hydra:description']) {
                setText('Error lors de la récupération des données')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/specialists')
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCreate = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError({})

        let data = new FormData(e.target)
        const name = data.get('name')
        data = { name: name, specialist: `/specialists/${id}` }

        const createdUser = await createCv(data)

        if (createdUser && createdUser.name) {
            setText('L\'utilisateur a été crée')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            handleOpenAddPopup()
        } else if (createdUser.violations) {
            const errorField = handleFormError(createdUser, ['name'])
            setError(errorField)
            setText('Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText((createdUser && createdUser['hydra:description']) || 'Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setLoading(false)
    }

    const handleChangePage = async (event, newPage) => {
        setLoadingPage(true)
        if (newPage > page) {
          await fetchCvs(view['hydra:next'])
        } else if (newPage < page) {
          await fetchCvs(view['hydra:previous'])
        }
        setLoadingPage(false)
        setPage(newPage);
      };

    const handleConfirmationDelete = (row) => {
        setOpenDeletePopup(openDeletePopup ? false : row.uuid)
    }


    const handleOpenAddPopup = () => {
        setOpenAddPopup(!openAddPopup)
    }

    const handleDownload = async (row, specific) => {
        const fetchBlob = await apiFetch(specific ? `/specialists/${id}/cv` : `${row['@id']}/download`, {}, false, false, true)

        if (fetchBlob.ok) {
          const blob = await fetchBlob.blob()
          download(blob, `FOR_CV_${moment().format('DD-MM-YYYY')}_${specialist.firstName}_${specialist.lastName.toUpperCase()}_V1`)
        }
    }

    const handleUpdate = async (row) => {
        navigate(`/cvs/${row.uuid}/edit`)
    }

    const handleDelete = async () => {
        const deletedCv = await deleteCv(openDeletePopup)
        if (deletedCv && deletedCv.ok) {
            setText('Le CV a été supprimé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setOpenDeletePopup(false)
        } else {
            setText('Erreur lors de la suppression du CV')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        }
    }

    return (
        <div>
            {cvs ? 
            <Zoom in={zoom}>
                <Paper style={{boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)'}}>
                    <EnhancedTableToolbar
                        handleDownload={handleDownload}
                        darkState={darkState}
                        handleDelete={handleConfirmationDelete}
                        handleOpenAddPopup={handleOpenAddPopup}
                    />
                    <TableContainer>
                        <Table
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                rowCount={cvs.length}
                                darkState={darkState}
                            />
                            <TableBody>
                                {cvs
                                    /* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */
                                    .map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <TableCell component="th" id={labelId} scope="row" padding="normal">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" padding="normal">
                                                    {moment(row.createdAt).format('DD MMMM YYYY')}
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" padding="normal">
                                                    {row.updatedAt ? moment(row.updatedAt).format('DD MMMM YYYY') : 'pas de mise à jour'}
                                                </TableCell>
                                                {isAdministrateur() &&
                                                <TableCell component="th" scope="row" padding="normal" style={{textAlign: 'end'}}>
                                                    <ButtonGroup variant="contained" color={darkState ? 'secondary' : "primary"}>
                                                        <Tooltip TransitionComponent={Zoom} title="Télécharger">
                                                            <IconButton color={darkState ? 'secondary' : "primary"} onClick={() => handleDownload(row)}>
                                                                <GetAppIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip TransitionComponent={Zoom} title={"Modifer"}>
                                                            <IconButton color={darkState ? 'secondary' : "primary"} onClick={() => handleUpdate(row)}>
                                                                <UpdateIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip TransitionComponent={Zoom} title={"Supprimer"}>
                                                            <IconButton color={darkState ? 'secondary' : "primary"} onClick={() => handleConfirmationDelete(row)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </ButtonGroup>
                                                </TableCell>}
                                            </TableRow>
                                        );
                                })}
                                {cvs.length === 0 && (
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Pas de cvs...</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            component="div"
                            count={totalItems}
                            nextIconButtonProps={{ disabled: loadingPage || page >= (totalItems / 30) - 1}}
                            backIconButtonProps={{ disabled: loadingPage || page === 0 }} 
                            rowsPerPage={30}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    </ThemeProvider>
                    <DeletedConfirmationPopup
                        handleAccept={handleDelete}
                        open={!!openDeletePopup}
                        handleClose={handleConfirmationDelete}
                        darkState={darkState}
                    />
                    <AddCv
                        handleCreate={handleCreate}
                        error={error}
                        loading={loading}
                        open={openAddPopup}
                        handleClose={handleOpenAddPopup}
                        darkState={darkState}
                    />
                    {loadingPage && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
                </Paper>
            </Zoom>:
            <div style={{textAlign: 'center'}}>
                <CircularProgress color={darkState ? 'secondary' : 'primary'} />
            </div>}
        </div>
    );
}
