import React from 'react'
import { 
    TableCell,
    TableSortLabel,
    TableRow,
    TableHead,
} from '@mui/material';
import {hasRoles} from '../../service/security/hasRoles';

export default function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, darkState, headCells } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <React.Fragment key={headCell.id}>
              {(headCell.label !== 'Action' || hasRoles() === 'administrateur') && <TableCell
                align={headCell.numeric ? 'right' : 'left'}
                padding={'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  style={{fontWeight: 'bold', color: darkState ? '#7fe1dd' : "#12047D"}}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span
                      style={{
                        border: 0,
                        clip: 'rect(0 0 0 0)',
                        height: 1,
                        margin: -1,
                        overflow: 'hidden',
                        padding: 0,
                        position: 'absolute',
                        top: 20,
                        width: 1,
                      }}
                    >
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>}
            </React.Fragment>
          ))}
        </TableRow>
      </TableHead>
    );
}