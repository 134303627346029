import React, { useState } from 'react'
import { Card, CardHeader, CardContent, IconButton, Typography, Menu, MenuItem, Tooltip, Zoom } from '@mui/material/';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeletedConfirmationPopup from '../../../components/DeletedConfirmationPopup';
import { useNavigate } from 'react-router-dom';
import {isGestionPsy} from '../../../service/security/hasRoles';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
export default function ProspectCard({
    prospect,
    handleDelete,
    darkState,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [deletePopupOpen, setDeletePopupOpen] = useState(false);
    let navigate = useNavigate()

    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteProspect = () => {
        handleDelete(prospect)
        setAnchorEl(null)
        setDeletePopupOpen(false)
    }

    const handleCloseConfirmationPopup = () => {
        setDeletePopupOpen(false)
    }

    const handleEditProspect = () => {
        navigate(`/prospects/${prospect.uuid}/edit`, { state: { action: "edit" } })
    }

    const handleConvertProspect = () => {
        navigate(`/office_specialist/${prospect.uuid}/convert`, { state: { action: "convert" } })
    }

    return (
        <Card>
            <CardHeader
                action={
                    <>
                        {isGestionPsy() && <Tooltip TransitionComponent={Zoom} title={'Convertir en psychologue en cabinet'}>
                            <IconButton color='inherit' aria-label="share" onClick={handleConvertProspect}>
                                <SyncAltIcon />
                            </IconButton>
                        </Tooltip>}
                        {isGestionPsy() && <><IconButton color='inherit' aria-label="settings" onClick={handleMoreClick}>
                            <MoreVertIcon />
                        </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleEditProspect}>Modifier</MenuItem>
                                <MenuItem onClick={() => setDeletePopupOpen(true)}>Supprimer</MenuItem>
                            </Menu></>}
                    </>
                }
                title={
                    <a href={`/prospects/${prospect.uuid}`}  style={{textDecoration: 'none', color: darkState ? 'white' : "black"}}>
                        <Typography component="h2">
                            {prospect.lastName || prospect.firstName
                                ? `${prospect.lastName.toUpperCase()} ${prospect.firstName.charAt(0).toUpperCase() + prospect.firstName.slice(1).toLowerCase()}`
                                : 'Non renseigné'}
                        </Typography>
                    </a>
                }
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p" style={{marginBottom: 5}}>
                    <span>Email : </span>{`${prospect.email}` || 'Non renseigné'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" style={{marginBottom: 5}}>
                    <span>Spécialisation : </span>{`${prospect.specialistType}` || 'Non renseigné'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" style={{marginBottom: 5}}>
                    <span>Public(s) ciblé(s) : </span>{prospect.targetAudiences.length
                    ? prospect.targetAudiences.join(' / ')
                    : 'Non renseigné'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" style={{marginBottom: 5}}>
                    <span>N° mobile pro : </span>{prospect.mobilePro || 'Non renseigné'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" style={{marginBottom: 5}}>
                    <span>Adresse(s) cabinet(s) : </span> {prospect.officeAddresses.length
                    ? prospect.officeAddresses.map((address, index) => (
                        <div key={index}>
                            <span> - {typeof address === 'string' ? address : address.address}</span>
                            <br/>
                        </div>
                    ))
                    : 'Non renseigné'}
                </Typography>

            </CardContent>
            <DeletedConfirmationPopup
                handleAccept={handleDeleteProspect}
                open={deletePopupOpen}
                handleClose={handleCloseConfirmationPopup}
                darkState={darkState}
            />
        </Card>
    )
}