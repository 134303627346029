import React, { useState } from 'react'
import { Typography,
    Button,
    Grid,
    TextField,
    InputAdornment,
    IconButton,
    LinearProgress ,
    Paper,
} from "@mui/material";
import login from '../img/Online_collaboration.svg'
import logo from '../img/logo-PCG.png'
import { Visibility, VisibilityOff, LockOpen } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { isKnownRoutes, TransitionLeft } from '../helpers/utils';
import AlertSnackBar from '../components/AlertSnackbar'
import AuthService from '../service/security/handleTokenProvider'
import { apiFetch } from '../service/apiFetch';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function Login() {
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [transition, setTransition] = useState(false)
    const [severity, setSeverity] = useState(false)
    const [message, setMessage] = useState(false)
    let navigate = useNavigate()
    const theme = useTheme();

    const phoneResponsive = useMediaQuery(theme.breakpoints.down('xs'));
    document.title = 'Codex - Connexion'

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        let data = new FormData(e.target)
        const email = data.get('username')
        const pass = data.get('password')

        try {
            data = { username: `${email}`, password: `${pass}` } 
            AuthService.login(data).then((response) => {
                response.json().then((responseData) => {
                    localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(responseData))
                    if(response.ok) {
                        apiFetch('/users/me').then((res) => {
                            if (res && res['@id']) {
                                localStorage.setItem('REACT_ROLE', JSON.stringify(res.roles))
                                if (localStorage.getItem('LAST_URL') && isKnownRoutes(localStorage.getItem('LAST_URL'))) {
                                    navigate(localStorage.getItem('LAST_URL'))
                                } else {
                                    navigate('/dashboard')
                                }
                            }
                        })
                    } else {
                        setLoading(false)
                        if (responseData.code === 401) {
                            setMessage('Nom d\'utilisateur ou mot de passe incorrect')
                            setSeverity('error')
                            setTransition(() => TransitionLeft)
                            setError(true)
                        } else {
                            setMessage(responseData.detail)
                            setSeverity('error')
                            setTransition(() => TransitionLeft)
                            setError(true)
                        }
                    }
                })
            }).catch((e) => {
                setError(e)
            })
        } catch (e) {
            setError(e)
            setLoading(false)
        }
    }
    
    return (
        <div>
            <Grid container>
                <Grid item xs={12} sm={8} sx={{mt: 0, textAlign: 'center', margin: 'auto', backgroundColor: '#eee'}} style={{minHeight: '100vh'}}>
                    <div style={{display: '-webkit-box', margin: '2rem'}}>
                        <img src={logo} alt='logo' style={{width: phoneResponsive ? 75 : 50}} />
                        <Typography variant='h5' style={{margin: 5}}>
                            Codex
                        </Typography>
                    </div>
                    <img src={login} alt="working" style={{wdith: phoneResponsive ? 250 : 750, marginTop: phoneResponsive ? -30 : 170}} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Paper
                        style={
                            {
                                    backgroundColor: 'white',
                                    minHeight: '100vh',
                                    paddingLeft: 60,
                                    paddingRight: 60,
                            }
                        }
                    >
                        <div style={{height: phoneResponsive ? 10 : 300}}></div>
                        <Typography
                            variant='h5'
                            color={
                                'primary'
                            }
                            style={{
                                fontSize: 23,
                                fontWeight: 'bold',
                                letterSpacing: 1,
                                marginBottom: 15
                            }}
                        >
                            Bienvenue sur l'espace Codex ! 
                        </Typography>
                        <Typography 
                            variant='h6'
                            color={'primary'} 
                            style={{fontSize: 15}}
                        >
                            Connectez-vous à votre compte pour accéder à l'application.
                        </Typography>
                        <form noValidate onSubmit={handleSubmit} autoComplete="on">
                            <TextField
                                color={'primary'}
                                sx={{width: '100%', mb: 2.5, mt: 2}}
                                id="username"
                                name="username"
                                label="Email"
                            /><br />
                            <TextField 
                                id="password" 
                                color={'primary'}
                                name="password" 
                                label="Mot de passe"
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                color='inherit'
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                sx={{width: '100%', mb: 2.5}}
                            />
                            <Button
                                disabled={loading}
                                type="submit"
                                variant="contained"
                                color={'primary'}
                                sx={{width: '100%', mb: 2.5}}
                                endIcon={<LockOpen />}
                            >
                                Se connecter
                            </Button>
                        </form>
                        {loading && <LinearProgress color={"primary"} />}
                    </Paper>
                </Grid>
            </Grid>
            <AlertSnackBar open={!!error} setOpen={setError} transition={transition} message={message} severity={severity} />
        </div>
    )
}