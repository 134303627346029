import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeletedConfirmationPopup({ open, handleClose, darkState, handleAccept, canceled, handleCancel }) {

  return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="delete-popup-title"
            aria-describedby="delete-popup-description"
            style={{zIndex: 1301}}
        >
            <DialogTitle id="delete-popup-title">{`Voulez-vous vraiment ${canceled ? 'annuler' : 'supprimer'} ?`}</DialogTitle>
            <DialogActions>
                <Button onClick={handleClose} color={darkState ? 'secondary' : 'primary'}>
                    Annuler
                </Button>
                <Button onClick={canceled ? handleCancel : handleAccept} color={darkState ? 'secondary' : 'primary'}>
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
  );
}