import React, { useEffect, useRef, useState } from 'react'
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    IconButton,
    Zoom,
    LinearProgress,
} from '@mui/material';
import EnhancedTableHead from './CompanyItem/EnhancedTableHead';
import { EnhancedTableToolbar } from './CompanyItem/EnhancedTableToolbar';
import moment from 'moment'
import 'moment/locale/fr'
import {isGestionPsy} from '../service/security/hasRoles';
import { useDisabledSpecialists } from '../hooks/restoreSpecialists';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { download, TransitionLeft } from '../helpers/utils';
import { apiFetch } from '../service/apiFetch';
import {ADMINISTRATEUR, GESTION_PSY, SUPER_ADMIN} from "../constantes/mainConstantes";

moment.locale('fr')

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Nom/Prénom' },
    { id: 'mail', numeric: false, disablePadding: false, label: 'Email' },
    ...(isGestionPsy() ? [{ id: 'restore', numeric: true, disablePadding: false, label: 'Réactivé' }] : []),
];

export default function RestoreSpecialist({ zoom, darkState, setOpenSnackbar, setText, setTransition, setSeverity }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const mounted = useRef(false);
    document.title = 'Codex - Gérer les Spécialistes supprimés'

    const {
        disabledSpecialists,
        loading,
        fetchDisabledSpecialists,
        deleteDisabledSpecialists,
    } = useDisabledSpecialists()
    
    useEffect(() => {
        (async () => {
            mounted.current = true
            await fetchDisabledSpecialists(null, () => mounted)
        })()

        return () => mounted.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
  
    const handleRestore = async (event, row) => {
        const restoreSpecialist = await deleteDisabledSpecialists(row.uuid)

        if (restoreSpecialist && restoreSpecialist.uuid) {
            setText(`Vous avez réactivé le spécialiste : ${restoreSpecialist.lastName} ${restoreSpecialist.firstName}`)
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            setText((restoreSpecialist && restoreSpecialist['hydra:description']) || 'Erreur lors de la réactivation')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    };

    const handlePurged = async () => {
        const exportPurgedRecord = await apiFetch(`/specialist/purged/record`, {}, false, false, true)
        
        if (exportPurgedRecord.ok) {
            const blob = await exportPurgedRecord.blob()
            download(blob, `Liste des spécialistes supprimés - ${moment().format('DD/MM/YYYY')}`)
        } else {
            setText('Erreur lors du téléchargement !')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }
    
    return (
      <Zoom in={zoom}>
        <div>
            {disabledSpecialists && <Paper>
                <EnhancedTableToolbar disableAddIcon={true} darkState={darkState} handlePurged={handlePurged} title={'Spécialistes supprimés'} allowedRoles={[GESTION_PSY, ADMINISTRATEUR, SUPER_ADMIN]}/>
                <TableContainer>
                    <Table
                      aria-labelledby="tableTitle"
                      aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={disabledSpecialists.length}
                            darkState={darkState}
                            headCells={headCells}
                        />
                        <TableBody>
                            {stableSort(disabledSpecialists, getComparator(order, orderBy))
                                /* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */
                                .map((row, index) => {
                                const labelId = `enhanced-table-${index}`;
                                
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.id}
                                    >
                                        <TableCell component="th" id={labelId} scope="row">
                                            {row.lastName} {row.firstName}
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row">
                                            {row.email}
                                        </TableCell>
                                        {isGestionPsy() && <TableCell align="right">
                                            <IconButton color='inherit' onClick={(event) => handleRestore(event, row)}>
                                                <RestoreFromTrashIcon />
                                            </IconButton> 
                                        </TableCell>}
                                    </TableRow>
                                );
                            })}
                            {disabledSpecialists.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={2} >Pas de spécialistes supprimés...</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                  {!disabledSpecialists && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
                </TableContainer>
            {loading && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
            </Paper>}
        </div>
      </Zoom>
    );
}