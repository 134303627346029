import React from 'react'
import { 
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    Zoom,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {isIntervention} from '../../service/security/hasRoles';

export const EnhancedTableToolbar = ({ handleNewIntervention, darkState }) => {

    return (
        <Toolbar>
            <Typography
                color={darkState ? 'secondary' : 'primary'}
                variant="h5"
                id="tableTitle"
                component="div"
                sx={{flex: '1 1 100%'}}
            >
                Interventions
            </Typography>
            {isIntervention() &&
            <Tooltip TransitionComponent={Zoom} title="Ajouter une intervention">
                <IconButton color='inherit' aria-label="add intervention" onClick={handleNewIntervention}>
                    <AddIcon />
                </IconButton>
            </Tooltip>}
        </Toolbar>
    );
};