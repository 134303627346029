import React from 'react';
import { 
    TableCell, 
    TableHead, 
    TableRow, 
    TableSortLabel, 
} from '@mui/material/';
import {isAdministrateur} from '../../../../service/security/hasRoles';

const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Nom Prénom' },
    { id: 'telephone', numeric: true, disablePadding: false, label: 'Téléphone' },
    { id: 'mail', numeric: true, disablePadding: false, label: 'Email' },
];

const headCellsActions = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Nom Prénom' },
    { id: 'telephone', numeric: true, disablePadding: false, label: 'Téléphone' },
    { id: 'mail', numeric: true, disablePadding: false, label: 'Email' },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action' },
];

export function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, darkState } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {findRightHeadCells(headCells, headCellsActions).map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            style={{fontWeight: 'bold', color: darkState ? '#7fe1dd' : "#12047D"}}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span
                                    style={{
                                        border: 0,
                                        clip: 'rect(0 0 0 0)',
                                        height: 1,
                                        margin: -1,
                                        overflow: 'hidden',
                                        padding: 0,
                                        position: 'absolute',
                                        top: 20,
                                        width: 1,
                                    }}
                                >
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function findRightHeadCells(headCells, headCellsActions) {
    if (isAdministrateur()) {
        return headCellsActions
    } 
    return headCells
}