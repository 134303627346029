import React from 'react'
import { 
    TableCell,
    TableSortLabel,
    TableRow,
    TableHead,
} from '@mui/material';
import {isIntervention} from '../../service/security/hasRoles';

const headCells = [
    { id: 'company', numeric: false, disablePadding: false, label: 'Nom de l\'entreprise' },
    { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
    { id: 'address', numeric: true, disablePadding: false, label: 'Adresse' },
    { id: 'specialist', numeric: true, disablePadding: false, label: 'Psychologue' },
    { id: 'startDate', numeric: true, disablePadding: false, label: 'Date de début' },
    { id: 'startTime', numeric: true, disablePadding: false, label: 'Heure de début' },
    ...(isIntervention() ? [{ id: 'action', numeric: true, disablePadding: false, label: 'Action' }] : []),
];

export default function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, darkState } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <React.Fragment key={headCell.id}>
              {(headCell.label !== 'Action' || isIntervention()) &&
              <TableCell
                align={headCell.numeric ? 'right' : 'left'}
                padding={'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  style={{fontWeight: 'bold', color: darkState ? '#7fe1dd' : "#12047D"}}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                      <span
                          style={{
                              border: 0,
                              clip: 'rect(0 0 0 0)',
                              height: 1,
                              margin: -1,
                              overflow: 'hidden',
                              padding: 0,
                              position: 'absolute',
                              top: 20,
                              width: 1,
                          }}
                      >
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>}
            </React.Fragment>
          ))}
        </TableRow>
      </TableHead>
    );
}