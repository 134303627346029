import React from 'react'
import {
    Paper,
    Typography,
    Avatar,
    Grid,
    Zoom,
    ButtonGroup,
    Button,
    IconButton
} from '@mui/material';
import darkProfil from '../../../../img/profil_account_dark.svg'
import profil from '../../../../img/profil_account.svg'
import { useNavigate } from 'react-router-dom';
import {isGestionPsy} from '../../../../service/security/hasRoles';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { apiFetch } from '../../../../service/apiFetch';
import { TransitionLeft } from '../../../../helpers/utils';

export default function SpecialistHeader({
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity,
    specialist,
    darkState,
    zoom,
    id,
    phoneResponsive
}) {
    let navigate = useNavigate()

    const handleModify = () => {
        navigate(`/specialists/${id}/edit`)
    }

    const handleBack = () => {
        navigate(`/specialists`)
    }

    const handleFavorite = async () => {
        const manageFavorite = await apiFetch(`/specialists/${id}/favorite`)
        if (manageFavorite && manageFavorite.success) {
            specialist.isFavorite = manageFavorite.status === 'removed' ? false : true
            setText(manageFavorite.message)
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            setText('Erreur le spécialist n\'a pas pu être ajouté au favoris')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }


    return (
        <Zoom in={zoom} style={{ transitionDelay: '100ms' }}>
            <Paper
                sx={{
                    padding: '0px 0px 10px 0px',
                    mt: 3.75
                }}
            >
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    {!phoneResponsive && <div
                        style={{
                            backgroundColor: darkState ? '#7fe1dd' : '#12047D',
                            padding: 10,
                            borderTopRightRadius: 4,
                            borderTopLeftRadius: 4
                        }}
                    >
                        <img src={darkState ? profil : darkProfil} alt="working" style={{width: 400}} />
                    </div>}
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={2}>
                            <Avatar
                                variant='rounded'
                                src={`${specialist.photo && specialist.photo.contentUrl}`}
                                aria-label="specialist"
                                style={phoneResponsive ? {width: 50, height: 50} : {width: 125, height: 125}}
                                sx={{
                                    width: 125,
                                    height: 125,
                                    borderRadius: '.357rem!important',
                                    border: '.357rem solid #fff',
                                    boxShadow: '0 0 8px 0 rgb(34 41 47 / 14%)',
                                    ml: {md: 3.75, sm: '40%'},
                                    mt: {md: -11.25, sm: 1.25},
                                }}
                            >
                                {specialist.firstName.charAt(0)}
                            </Avatar>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={9}
                            sx={{
                                textAlign: {sm: 'left', xs: 'center'},
                                mt: {sm: -10, xs: 0},
                                mb: {sm: 0, xs: 3.75}
                            }}
                        >
                            <Typography
                                variant='h6'
                                sx={{
                                        color: darkState ? '#12047D' : '#fff',
                                        fontWeight: 'bold',
                                }}
                            >
                                {specialist.title} {specialist.lastName} {specialist.firstName}
                            </Typography>
                            <Typography
                                variant='body2'
                                sx={{
                                    color: darkState ? {sm: '#333', xs: '#fff'} : {sm: '#ddd', xs: '#fff'},
                                }}
                            >
                                {specialist.email}
                            </Typography>
                            <Typography
                                variant='body2'
                                sx={{
                                    color: darkState ? {sm: '#333', xs: '#fff'} : {sm: '#ddd', xs: '#fff'},
                                }}
                            >
                                {specialist.address && `${specialist.address.address}, ${specialist.address.city}`}
                            </Typography>
                            <Typography
                                variant='body2' 
                                style={
                                    {
                                        marginTop: 10,
                                        maxWidth: '500px',
                                        overflow: 'hidden',
                                        display: 'block',
                                        textOverflow: 'ellipsis',
                                        wordWrap: 'break-word',
                                        maxHeight: '6em',
                                        lineHeight: '1.5em',
                                    }
                                }
                            >
                                {specialist.address && `${specialist.descriptionCvHeader}`}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div
                        style={{
                            marginLeft: phoneResponsive ? 0 : 172,
                            display: phoneResponsive ? 'block' : 'flex',
                            marginTop: -20,
                            marginRight: phoneResponsive ? 10 : 0,
                            marginBottom: phoneResponsive ? 40 : 0,
                            paddingBottom: specialist.administrativeTypes.length ? 10 : 40,
                        }}
                    >
                        {specialist.administrativeTypes.map((type, index) => {
                            return <Typography
                                key={index}
                                variant='body1'
                                style={
                                    {
                                        color : 'white',
                                        border: `1px solid ${findAdminColor(type.name)}`,
                                        borderRadius: 5,
                                        padding: 5,
                                        backgroundColor: findAdminColor(type.name),
                                        textAlign: 'left',
                                        marginLeft: 10,
                                        marginTop: 30
                                    }
                                }
                            >
                                {type.name}
                            </Typography>
                        })}
                        <ButtonGroup
                            style={{position: 'absolute', right: 40}}
                            variant="contained"
                            color={darkState ? 'secondary': "primary"}
                            aria-label="contained primary button group"
                        >
                            <IconButton color='inherit' aria-label="add to favorites" onClick={() => handleFavorite(specialist.uuid)}>
                                {specialist.isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                            </IconButton>
                            {isGestionPsy() && <Button onClick={handleModify}>Modifier</Button>}
                            <Button onClick={handleBack}>Retour</Button>
                        </ButtonGroup>
                    </div>
                </div>
            </Paper>
        </Zoom>
    )
}

function findAdminColor(type) {
    switch(type) {
        case 'Pros-Consulte':
            return '#EE8639'
        case 'Geo-Psy':
            return '#f44336'
        default:
            return '#ba000d'
    }
}