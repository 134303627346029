import React, { useState } from 'react';
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Paper,
    ThemeProvider,
    createTheme,
    IconButton,
    Menu,
    Avatar,
    MenuItem,
    Typography,
    LinearProgress,
} from '@mui/material/';
import { EnhancedTableHead } from './TableComponents/EnhancedTableHead';
import { EnhancedTableToolbar } from './TableComponents/EnhancedTableToolbar';
import * as locales from '@mui/material/locale';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeletedConfirmationPopup from '../../../components/DeletedConfirmationPopup';
import { useNavigate } from 'react-router-dom';
import {isAdministrateur} from '../../../service/security/hasRoles';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
            return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTable({ totalItems, view, fetchSpecialists, rows=[], handleDelete, darkState }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [openMenu, setOpenMenu] = useState(null);
    const [locale] = useState('frFR');
    const [deletePopupOpen, setDeletePopupOpen] = useState(false);
    const [selected, setSelected] = useState(false);
    let navigate = useNavigate()

    const handleDeleteSpecialist = () => {
        handleDelete(selected)
        setOpenMenu(null)
        setDeletePopupOpen(false)
    }
    
    const handleCloseConfirmationPopup = () => {
        setDeletePopupOpen(false)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = async (event, newPage) => {
        setLoading(true)
        if (newPage > page) {
          await fetchSpecialists(view['hydra:next'])
        } else if (newPage < page) {
          await fetchSpecialists(view['hydra:previous'])
        }
        setLoading(false)
        setPage(newPage);
    };

    const handleClickAction = (event, row) => {
        setOpenMenu(event.currentTarget);
        setSelected(row)
    };

    const handleCloseAction = () => {
        setOpenMenu(null);
    };

    const handleShowSpecialist = (e, row) => {
        navigate(`/specialists/${selected.uuid || (row && row.uuid)}`)
    }

    const handleEditSpecialist = () => {
        navigate(`/specialists/${selected.uuid}/edit`)
    }

    return (
        <div style={{marginTop: 60}}>
            <Paper>
                <EnhancedTableToolbar />
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                          order={order}
                          darkState={darkState}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                /* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */
                                .map((row, index) => {
                                    const labelId = `enhanced-table-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            <TableCell
                                                style={{cursor: 'pointer'}}
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="normal"
                                                onClick={(event) => handleShowSpecialist(event, row)}
                                            >
                                                <div style={{display: 'flex'}}>
                                                    <Avatar
                                                        style={{marginRight: 8}}
                                                        src={`${row.photo && row.photo.contentUrl}`}
                                                        aria-label="specialist"
                                                    >
                                                        {row.firstName.charAt(0)}
                                                    </Avatar>
                                                    <Typography variant='body2' style={{marginleft: 5, marginTop: 8}} >
                                                    {row.firstName} {row.lastName}
                                                    </Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">{row.mobilePro}</TableCell>
                                            <TableCell align="right">{row.email}</TableCell>
                                            {isAdministrateur() && <TableCell align="right">
                                                <IconButton color='inherit' onClick={(event) => handleClickAction(event, row)}>
                                                    <MoreVertIcon />    
                                                </IconButton>
                                            </TableCell>}
                                        </TableRow>
                                    );
                                })}
                            {rows.length === 0 && (
                                <TableRow style={{ height: 53 }}>
                                    <TableCell colSpan={6} style={{textAlign: 'center'}}>Pas de psychologue...</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                    <TablePagination
                        labelRowsPerPage=''
                        rowsPerPageOptions={[]}
                        component="div"
                        count={totalItems}
                        nextIconButtonProps={{ disabled: loading || page >= (totalItems / 30) - 1}}
                        backIconButtonProps={{ disabled: loading || page === 0 }} 
                        rowsPerPage={30}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </ThemeProvider>
                {loading && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
            </Paper>
            <Menu
                id='action-menu'
                anchorEl={openMenu}
                keepMounted
                open={Boolean(openMenu)}
                onClose={handleCloseAction}
            >
                <MenuItem onClick={handleEditSpecialist}>Modifier</MenuItem>
                <MenuItem onClick={() => setDeletePopupOpen(true)}>Supprimer</MenuItem>
                <MenuItem onClick={handleShowSpecialist}>Voir</MenuItem>
            </Menu>
            <DeletedConfirmationPopup
                handleAccept={handleDeleteSpecialist}
                open={deletePopupOpen}
                handleClose={handleCloseConfirmationPopup}
                darkState={darkState}
            />
        </div>
    );
}