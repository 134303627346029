import { ADMINISTRATEUR, SUPER_ADMIN, USER, APPEL_OFFRE, COMMERCIAL, PROJET, INTERVENTION, GESTION_PSY } from "../../constantes/mainConstantes";
import { isAuth } from "./isAuth";

export function hasRoles() {
    if(isAuth() && getRole()) {
        if (getRole()[0] === USER) {
            return 'user'
        } else if (getRole()[0] === ADMINISTRATEUR || getRole()[0] === SUPER_ADMIN) {
            return 'administrateur'
        } else if (getRole()[0] === APPEL_OFFRE) {
            return 'appel_offre'
        } else if (getRole()[0] === COMMERCIAL) {
            return 'commercial'
        } else if (getRole()[0] === PROJET) {
            return 'projet'
        } else if (getRole()[0] === INTERVENTION) {
            return 'intervention'
        } else if (getRole()[0] === GESTION_PSY) {
            return 'gestion_psy'
        }
        return null
    }
}

export function isSuperAdmin() {
    if (isAuth() && getRole()) {
        return getRole()[0] === SUPER_ADMIN
    }
}

export function isAdministrateur() {
    if (isAuth() && getRole()) {
        return getRole()[0] === ADMINISTRATEUR || getRole()[0] === SUPER_ADMIN
    }
}

export function isGestionPsy() {
    if (isAuth() && getRole()) {
        return getRole()[0] === GESTION_PSY || getRole()[0] === ADMINISTRATEUR || getRole()[0] === SUPER_ADMIN
    }
}

export function isAppelOffreOrCommercialOrProjetOrGestionPsy() {
    if (isAuth() && getRole()) {
        return getRole()[0] === APPEL_OFFRE || getRole()[0] === COMMERCIAL || getRole()[0] === PROJET || getRole()[0] === GESTION_PSY || getRole()[0] === ADMINISTRATEUR || getRole()[0] === SUPER_ADMIN
    }
}

export function isIntervention() {
    if (isAuth() && getRole()) {
        return getRole()[0] === INTERVENTION || getRole()[0] === ADMINISTRATEUR || getRole()[0] === SUPER_ADMIN
    }
}

function getRole() {
    return JSON.parse(localStorage.getItem('REACT_ROLE'))
}

export function hasOneOfRoles(roles = []) {
    const userRole = getRole()?.[0];

    if (isAuth() && userRole) {
        return roles.includes(userRole);
    }
    return false;
}