import React, { useEffect, useState } from 'react'
import { apiFetch } from '../service/apiFetch'
import { Grid, Zoom, CircularProgress } from '@mui/material/';
import { TransitionLeft } from '../helpers/utils';
import AdministrativeType from './DashboadItem/AdministrativeType';
import Documents from './DashboadItem/Documents';
import OngoingInterventions from './DashboadItem/OngoingInterventions';
import ThisMonthInterventions from './DashboadItem/ThisMonthInterventions';
import MapContainer from './SpecialistsMap'


export default function Dashboard({ phoneResponsive, darkState, zoom, setOpenSnackbar, setText, setTransition, setSeverity }) {
    const [dashboardData, setDashboardData] = useState(false)
    const [specialistsData, setSpecialistsData] = useState(false)
    document.title = 'Codex - Tableau de bord'

    useEffect(() => {
        (async () => {
            const dashboard = await apiFetch('/dashboard')
            if (dashboard && Object.getOwnPropertyNames(dashboard).length) {
                setDashboardData(dashboard)
            } else {
                setText('Erreur lors de la récupération des données')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }
            const specialists = await apiFetch('/specialists/map')
            if (specialists && specialists['hydra:member']) {
                setSpecialistsData(specialists)
            } else {
                setText('Erreur lors de la récupération des données')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }
        })()
    }, [setText, setTransition, setSeverity, setOpenSnackbar])

    return (
        <Zoom in={zoom} style={phoneResponsive && dashboardData.ongoingInterventions ? {marginTop: 10, maxWidth: 240} : phoneResponsive ? {marginTop: 10, maxWidth: 240} : {marginTop: 30}}>
            <Grid container spacing={3}>
                {!dashboardData ?
                    <div style={{textAlign: 'center', width: '100%'}}>
                        <CircularProgress color={darkState ? 'secondary' : 'primary'} />
                    </div>
                    :
                    <>
                        <Grid item xs={12} sm={6}>
                            {dashboardData.administrativeType && <AdministrativeType dashboardData={dashboardData} phoneResponsive={phoneResponsive} darkState={darkState} zoom={zoom} />}
                            {/* {dashboardData.incompleteProfile && <IncompleteProfile data={dashboardData.incompleteProfile} phoneResponsive={phoneResponsive} darkState={darkState} zoom={zoom} />} */}
                            {dashboardData.ongoingInterventions && <OngoingInterventions data={dashboardData.ongoingInterventions} phoneResponsive={phoneResponsive} darkState={darkState} zoom={zoom} />}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {specialistsData && <MapContainer specialists={specialistsData} dashboard={true} />}
                            {dashboardData.thisMonthInterventions && <ThisMonthInterventions data={dashboardData.thisMonthInterventions} phoneResponsive={phoneResponsive} darkState={darkState} zoom={zoom} />}
                            {/* {dashboardData.holidays && <Holidays data={dashboardData.holidays.holidays} phoneResponsive={phoneResponsive} darkState={darkState} zoom={zoom} />} */}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {dashboardData.documents && <Documents data={dashboardData.documents} phoneResponsive={phoneResponsive} darkState={darkState} zoom={zoom} />}
                        </Grid>
                    </>
                }
            </Grid>
        </Zoom>
    )
}