import React, { useEffect } from 'react'
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import Avatar from "@mui/material/Avatar"
import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import DeleteIcon from "@mui/icons-material/Delete"
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add';
import { apiFetch } from '../service/apiFetch'

export default function UploadDocuments({ setDocumentTypes, isList, setStartDate, setDocuments, files, setFiles, fileUrl, setFileUrl }) {

    useEffect(() => {
        (async () => {
            const getDocumentType = await apiFetch('/document_types')
            if (getDocumentType && getDocumentType['hydra:member']) {
                setDocumentTypes(getDocumentType['hydra:member'])
            }
        })()
    }, [setDocumentTypes])

    const handleFileChanged = e => {
        if (!e.target.files[0]) return null
        const inputDocuments = document.querySelector("#inputDocuments");
        const objectFiles = inputDocuments.files;
        const filesInput = Object.keys(objectFiles).map(index => {
            return objectFiles[index]
        });

        setFiles([...files, filesInput])
        setDocuments([...files, filesInput])
        const reader = new FileReader()
        reader.onload = e => {
            setFileUrl([...fileUrl, e.target.result])
        }
        reader.readAsDataURL(e.target.files[0])
    };
    
    const handleDeleteFile = (lastModified, fileUrlIndex) => _ => {
        const updatedFiles = files.filter(f => f[0].lastModified !== lastModified)
        const updatedFileUrl = fileUrl.filter(f => f !== fileUrlIndex)
        setFiles(updatedFiles)
        setFileUrl(updatedFileUrl)
        setDocuments(updatedFiles)
        setStartDate && setStartDate(null)
    };

    return (
        <Grid container spacing={2} style={{marginLeft: '-7px', marginBottom: 30}}>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    onClick={() => document.querySelector("#inputDocuments").click()}
                    size="small"
                    disabled={files.length > 0}
                >
                    <AddIcon/>
                    {'Charger un document'}
                </Button>
                <input
                    type="file"
                    id="inputDocuments"
                    style={{ visibility: "hidden" }}
                    multiple
                    onChange={handleFileChanged}
                    accept={isList ? '.csv' : ".jpg,.jpeg,.png,.doc,.docx,.pdf,.xlsx"}
                />
            </Grid>
            <Grid item xs={12}>
                <div>
                    {files.length > 0 && <List>
                        {files.map((f, index) => (
                            <React.Fragment key={f[0].lastModified}>
                                <ListItem >
                                    {!isList && <ListItemAvatar>
                                        <Avatar alt="Avatar" src={fileUrl[index]}/>
                                    </ListItemAvatar>}
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                type='body2'
                                                style={{
                                                    maxWidth: 410,
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {f[0].name}
                                            </Typography>
                                        }
                                        secondary={f[0].type}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            color='inherit'
                                            edge="end"
                                            aria-label="delete"
                                            onClick={handleDeleteFile(f[0].lastModified, fileUrl[index])}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </List>}
                </div>
            </Grid>
        </Grid>
    )
}