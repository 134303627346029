import React from 'react'
import { hasRoles } from '../security/hasRoles'
import { Navigate } from 'react-router-dom'

export const InterventionRoute = ({ children }) => {

    if (hasRoles() === 'intervention' || hasRoles() === 'administrateur') {
        return children
    }
    return <Navigate to='/specialists' />
}