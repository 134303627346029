import React, { useEffect, useRef, useState } from 'react'
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Paper,
    createTheme,
    ThemeProvider,
    Menu,
    MenuItem,
    IconButton,
    Zoom,
    LinearProgress,
} from '@mui/material';
import * as locales from '@mui/material/locale';
import EnhancedTableHead from './CompanyItem/EnhancedTableHead';
import { EnhancedTableToolbar } from './CompanyItem/EnhancedTableToolbar';
import moment from 'moment'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import 'moment/locale/fr'
import { useNavigate } from 'react-router-dom';
import { TransitionLeft } from '../helpers/utils';
import {isGestionPsy} from '../service/security/hasRoles';
import { useCompanies } from '../hooks/company';
import DeletedConfirmationPopup from '../components/DeletedConfirmationPopup'
import {ADMINISTRATEUR, GESTION_PSY, SUPER_ADMIN} from "../constantes/mainConstantes";
moment.locale('fr')

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Nom de l\'entreprise' },
  { id: 'nbEmployee', numeric: true, disablePadding: false, label: 'Nombre d\'employés' },
    ...(isGestionPsy() ? [{ id: 'action', numeric: true, disablePadding: false, label: 'Action' }] : []),
];

export default function Company({ zoom, darkState, setOpenSnackbar, setText, setTransition, setSeverity }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [locale] = useState('frFR');
    const mounted = useRef(false);
    let navigate = useNavigate()
    document.title = 'Codex - Gérer les entreprises'

    const {
        companies,
        totalItems,
        view,
        fetchCompanies,
        deleteCompany,
    } = useCompanies()
    
    useEffect(() => {
        (async () => {
            mounted.current = true
            await fetchCompanies(null, () => mounted)
        })()

        return () => mounted.current = false
    }, [fetchCompanies])

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleChangePage = async (event, newPage) => {
      setLoading(true)
      if (newPage > page) {
        await fetchCompanies(view['hydra:next'])
      } else if (newPage < page) {
        await fetchCompanies(view['hydra:previous'])
      }
      setLoading(false)
      setPage(newPage);
  };
  
    const handleClick = (event, row) => {
        setSelected(row);
        setOpenMenu(event.currentTarget);
    };
    
    const handleCloseAction = () => {
        setOpenMenu(null);
    };

    const handleNewCompany = () => {
        navigate('/companies/new')
    }

    const handleEditCompany = () => {
        navigate(`/companies/${selected.uuid}/edit`)
    }

    const handleConfirmationDelete = () => {
      setOpenMenu(null)
      setOpenDeletePopup(!openDeletePopup)
    }
    
    const handleDelete = async () => {
        setOpenMenu(false)
        setOpenDeletePopup(!openDeletePopup)
        const deletedCompany = await deleteCompany(selected.uuid)

        if (deletedCompany && deletedCompany.ok) {
          setText('L\'entreprise a été supprimé')
          setTransition(() => TransitionLeft)
          setSeverity('success')
          setOpenSnackbar(true)
      } else {
          setText((deletedCompany && deletedCompany['hydra:description']) || 'Erreur lors de la suppression')
          setTransition(() => TransitionLeft)
          setSeverity('error')
          setOpenSnackbar(true)
      }
    }

    return (
      <Zoom in={zoom}>
      <div>
        {companies && <Paper>
           <EnhancedTableToolbar darkState={darkState} numSelected={selected.length} title={'Entreprise'} handleNew={handleNewCompany} allowedRoles={[GESTION_PSY, ADMINISTRATEUR, SUPER_ADMIN]}/>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={companies.length}
                darkState={darkState}
                headCells={headCells}
              />
              <TableBody>
                {stableSort(companies, getComparator(order, orderBy))
                  /* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */
                  .map((row, index) => {
                    const labelId = `enhanced-table-${index}`;
  
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell component="th" id={labelId} scope="row" padding="normal">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.nbEmployee}</TableCell>
                        {isGestionPsy() &&
                        <TableCell align="right">
                            <IconButton color='inherit' onClick={(event) => handleClick(event, row)}>
                                <MoreVertIcon />
                            </IconButton> 
                        </TableCell>}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {!companies && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
          </TableContainer>
          <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
            <TablePagination
                labelRowsPerPage=''
                rowsPerPageOptions={[]}
                component="div"
                count={totalItems}
                nextIconButtonProps={{ disabled: loading || page >= (totalItems / 30) - 1}}
                backIconButtonProps={{ disabled: loading || page === 0 }} 
                rowsPerPage={30}
                page={page}
                onPageChange={handleChangePage}
            />
          </ThemeProvider>
          {loading && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
            <Menu
                id='action-menu'
                anchorEl={openMenu}
                keepMounted
                open={Boolean(openMenu)}
                onClose={handleCloseAction}
            >
                <MenuItem onClick={handleEditCompany}><EditIcon style={{marginRight: 8}}/> Modifier</MenuItem>
                <MenuItem onClick={(handleConfirmationDelete)}><DeleteIcon style={{marginRight: 8}}/> Supprimer</MenuItem>
            </Menu>
        </Paper>}
        <DeletedConfirmationPopup handleAccept={handleDelete} open={openDeletePopup} handleClose={handleConfirmationDelete} darkState={darkState} />
      </div>
      </Zoom>
    );
}