import React, { useState } from 'react'
import { 
    Paper,
    Zoom,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText, 
    DialogTitle,
} from '@mui/material';
import moment from 'moment'
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import ConfirmationPopup from './ConfirmationPopup';
import {isIntervention} from "../../../service/security/hasRoles";

export default function InterventionRequest({ zoom, darkState, intervention, handleSelectDate, disabled, state, setState, handleSendBack }) {
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false)
    const [selectedResponse, setSelectedResponse] = useState(null);
    const handleChange = (event) => {
        setState([...state, {[event.target.name]: event.target.checked} ]);
        
    };
    

    const handleClick = (req) => {
        const win = window.open(`/specialists/${req.specialist.uuid}`, "_blank");
        win.focus();
    }

    const handleSelect = (request) => {
        if (request.specialist.planning &&
            request.specialist.planning.events &&
            request.specialist.planning.events.length
        ) return setOpenConfirmationPopup(request)
        return handleSelectDate(request)
    }

    const handleAccept = () => {
        handleSelectDate(openConfirmationPopup)
        setOpenConfirmationPopup(false)
    }
    const handleClickOpen = (response) => {
        setSelectedResponse(response);
    };
    const handleClose = () => {
        setSelectedResponse(false);
    };
    return (
        <Zoom in={zoom} style={{ transitionDelay: '400ms' }}>
            <Paper style={{padding: 20, marginTop: handleSelectDate ? 30 : 0}}>
                <Typography variant='h6' color={darkState ? 'secondary' : 'primary'}>Liste des demandes : </Typography>
                <TableContainer>
                    <Table aria-label="table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Spécialistes</TableCell>
                                <TableCell align="right">Date d'envoi</TableCell>
                                <TableCell align="right">Statut</TableCell>
                                <TableCell align="right">Disponible</TableCell>
                                <TableCell align="right">Date choisie par le psy</TableCell>
                                <TableCell align="right">Réponse</TableCell>
                                <TableCell align="right">Date de Réponse</TableCell>
                                {isIntervention() && !intervention.specialist && <TableCell align="right">Action</TableCell>}
                                {isIntervention() && !intervention.specialist && <TableCell align="right">Renvoyer un SMS</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {intervention.interventionRequests &&
                            !!intervention.interventionRequests.length ?
                            intervention.interventionRequests.map((request) => (
                                <TableRow key={request.id} style={{cursor: 'pointer'}}>
                                    <TableCell component="th" scope="row" onClick={() => handleClick(request)}>
                                        {request.specialist.firstName} {request.specialist.lastName}
                                    </TableCell>
                                    <TableCell align="right">{moment(request.createdAt).format('DD MMMM YYYY à HH:mm')}</TableCell>
                                    <TableCell align="right">
                                        {request.answer ? 
                                        <CheckIcon style={{color: 'green'}} />: 
                                        <RemoveIcon />}
                                    </TableCell>
                                    <TableCell align="right">
                                        {!!request.answers.length ? (
                                            request.answers.map((item, index) => {
                                                return (
                                                    <Typography key={index}>
                                                        {item.accepted != null ? (item.accepted ? 'oui' : 'non') : 'Non renseigné'}
                                                    </Typography>
                                                );
                                            })
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>

                                    <TableCell align="right">
                                        {request.answers && Array.isArray(request.answers) && request.answers.length ? (
                                            request.answers.map((item, index) => (
                                                <div key={index}>
                                                    {item.chosenDates && Array.isArray(item.chosenDates) ? (
                                                        item.chosenDates.map((date, i) => (
                                                            <Typography key={i}>
                                                                {moment(date).format('DD MMMM YYYY')}
                                                            </Typography>
                                                        ))
                                                    ) : '-'}
                                                </div>
                                            ))
                                        ) : ('-')   
                                        }
                                    </TableCell>
                                    <TableCell align="right">
                                        {!!request.answers.length ? request.answers.map((item, i) => {
                                            const Response = item.response.length > 33 ? `${item.response.slice(0, 33)}...` : item.response;
                                            return (
                                                <div key={i}>
                                                    <Typography variant="body2">
                                                        {Response} 
                                                    </Typography>
                                                    {item.response.length > 33 && (
                                                        <Button size="small" color="primary" onClick={() => handleClickOpen(item)}>
                                                            Voir plus
                                                        </Button>
                                                    )}
                                                </div>
                                            )
                                        }) : '-'}
                                    </TableCell>
                                    <TableCell align="right">
                                    {!!request.answers.length ? request.answers.map((item, i) => {
                                        return (
                                            <Typography key={i}>
                                                {item && moment(item.createdAt).format('DD MMMM YYYY à HH:mm')}
                                            </Typography>
                                        )
                                    }) : '-'}
                                    </TableCell>
                                    {isIntervention() && !intervention.specialist &&
                                        <TableCell align="right">
                                            <Button
                                                disabled={disabled}
                                                color={darkState ? 'secondary' : 'primary'}
                                                variant='contained'
                                                onClick={() => handleSelect(request)}
                                            >
                                                Choisir ce spécialiste
                                            </Button>
                                        </TableCell>}
                                    {isIntervention() && (
                                        !request.smsUpToDate ?
                                        <TableCell align="right">
                                            <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={state.uuid}
                                                    onChange={handleChange}
                                                    name={request.uuid}
                                                    color={darkState ? 'secondary' : "primary"}
                                                  />
                                                }
                                            />
                                        </TableCell> :
                                    <TableCell>
                                    </TableCell>)}
                                </TableRow>
                            )) : 
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    Aucune demande pour le moment...    
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                    {isIntervention() && intervention.interventionRequests &&
                    !!intervention.interventionRequests.length &&
                    intervention.interventionRequests.find((intervention) => !intervention.smsUpToDate) && 
                        <Button
                            disabled={disabled}
                            color={darkState ? 'secondary' : 'primary'}
                            variant='contained'
                            onClick={handleSendBack}
                            style={{
                                marginTop: 10,
                                float: 'right',
                            }}
                        >
                            Renvoyer le SMS
                        </Button>}
                </TableContainer>
                <ConfirmationPopup
                    open={openConfirmationPopup}
                    handleClose={() => setOpenConfirmationPopup(false)}
                    handleAccept={handleAccept}
                    darkState={darkState}
                />
                
                <Dialog open={!!selectedResponse} onClose={handleClose} fullWidth> 
                    <DialogTitle>Commentaire</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {selectedResponse && selectedResponse.response}
                            </DialogContentText>
                        </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Retour
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </Zoom>
    )
}