import React, { useState } from 'react';
import { 
    Zoom,
    createTheme,
    ThemeProvider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper 
} from '@mui/material';
import moment from 'moment'
import * as locales from '@mui/material/locale';
import 'moment/locale/fr'
import { translateStatus } from '../../helpers/utils';

moment.locale('fr')

const headCells = [
    { id: 'company', numeric: false, disablePadding: false, label: 'Entreprise' },
    { id: 'specialist', numeric: true, disablePadding: false, label: 'Spécialiste' },
    { id: 'address', numeric: true, disablePadding: false, label: 'Adresse' },
    { id: 'startDate', numeric: true, disablePadding: false, label: 'Date de début' },
    { id: 'duration', numeric: true, disablePadding: false, label: 'Durée' },
    { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
];

function EnhancedTableHead() {

  return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        <TableSortLabel>
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = ({ darkState }) => {

    return (
        <Toolbar>
            <Typography color={darkState ? 'secondary' : 'primary'} variant="h6" id="tableTitle" component="div">
                Interventions ce mois-ci
            </Typography>
        </Toolbar>
    );
};

export default function ThisMonthInterventions({ data, darkState, zoom }) {
    const [page, setPage] = useState(0);
    const [locale] = useState('frFR');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <Zoom in={zoom} style={{marginTop: 30}}>
            <Paper>
                <EnhancedTableToolbar darkState={darkState} />
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead />
                        <TableBody>
                            {data
                                .slice(page * 5, page * 5 + 5)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-${index}`;
                                    
                                    return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.id}
                                    >
                                        <TableCell component="th" id={labelId} scope="row" padding="normal">
                                            {row.company.name}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.specialist ? `${row.specialist.lastName} ${row.specialist.firstName}` : ''}
                                        </TableCell>
                                        <TableCell align="right">{row.address.address}</TableCell>
                                        <TableCell align="right">{moment(row.startDate).format('DD MMM YYYY')}</TableCell>
                                        <TableCell align="right">{row.duration}</TableCell>
                                        <TableCell align="right">{translateStatus(row.status)}</TableCell>
                                    </TableRow>
                                );
                            })}
                            {data.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} >Pas d'intervention à venir...</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                    <TablePagination
                        labelRowsPerPage=''
                        rowsPerPageOptions={[]}
                        component="div"
                        count={data.length}
                        rowsPerPage={5}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </ThemeProvider>
            </Paper>
        </Zoom>
    );
}