import React, { useEffect, useRef, useState } from 'react'
import {
    Tooltip,
    IconButton,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    Grid,
    Paper,
    TextField,
    Zoom,
    Typography,
    Divider,
    Button,
    ButtonGroup,
    LinearProgress,
    FormHelperText 
} from '@mui/material';
import { DatePickerComponent } from '../../components/DateTimePickerComponent';
import AutoCompleteAddress from '../../components/AutoCompleteAddress';
import { useNavigate, useParams } from 'react-router-dom';
import { useInterventions } from '../../hooks/interventions';
import { formatAddress, TransitionLeft } from '../../helpers/utils';
import { apiFetch } from '../../service/apiFetch';
import { handleFormError } from '../../service/security/formError';
import AutoCompleteCompany from './AutoCompleteCompany';
import { useCompanies } from '../../hooks/company';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import DeleteIcon from "@mui/icons-material/Delete"
import moment from 'moment';
import { useSpecialists } from '../../hooks/specialist';
import Autocomplete from '@mui/material/Autocomplete';
import ConfirmationPopup from './ManageInterventionItem/ConfirmationPopup';

export default function NewIntervention({ darkState, zoom, setOpenSnackbar, setText, setTransition, setSeverity, phoneResponsive }) {
    const [startDates, setStartDates] = useState([moment()]);
    const [newAddress, setNewAddress] = useState(null);
    const [newCoordonnate, setNewCoordonnate] = useState(null);
    const [interventionTypes, setInterventionTypes] = useState(null);
    const [selectedIntervention, setSelectedIntervention] = useState('');
    const [intervention, setIntervention] = useState(false)
    const [openConfirPopup, setOpenConfirmPopup] = useState(false)
    const [specialist, setSpecialist] = useState(null)
    const [inputSpecialistValue, setInputSpecialistValue] = useState('')
    const [remuneration, setRemuneration] = useState('')
    const [num, setNum] = useState('')
    const [comment, setComment] = useState('')
    const [label, setLabel] = useState('')
    const [company, setCompany] = useState({
        name: '',
        nbEmployee: '',
    })
    const [state, setState] = useState({
        startHour: '',
        endHour: '',
        startMinute: '',
        endMinute: '',
    })
    const [error, setError] = useState({});
    let navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const mounted = useRef(false);

    const {
        companies,
        fetchCompanies,
        createCompany,
    } = useCompanies()

    const {
        createIntervention,
    } = useInterventions()

    const {
        specialists,
        fetchSpecialists,
    } = useSpecialists()
    
    useEffect(() => {
        (async () => {
            if (id) {
                const interventionFetch = await apiFetch(`/interventions/${id}`)
                if (interventionFetch && interventionFetch.uuid) {
                    setStartDates(interventionFetch.startDate)
                    setNewAddress(interventionFetch.address)
                    setCompany({
                        name: interventionFetch.company.name,
                        nbEmployee: interventionFetch.company.nbEmployee
                    })
                    setIntervention(interventionFetch)
                } else {
                    setSeverity('error')
                    setText('Error lors de la récupération des données')
                    setTransition(() => TransitionLeft)
                    setOpenSnackbar(true)
                    navigate('/interventions')
                }
            }
            mounted.current = true
            await fetchCompanies('/companies?pagination=false', () => mounted)
            const fetchInterventionTypes = await apiFetch('/intervention_types')
            if (fetchInterventionTypes && fetchInterventionTypes['hydra:member']) {
                setInterventionTypes(fetchInterventionTypes['hydra:member'])
            } else {
                setSeverity('error')
                setText('Error lors de la récupération des données')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/interventions')
            }
            mounted.current = true
            await fetchSpecialists('/specialists?pagination=false', () => mounted)
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, navigate, setOpenSnackbar, setSeverity, setTransition, setText])

    const handleBack = () => {
        navigate('/interventions')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        await handleCreate(e)
        setLoading(false)
    }

    const handleCreate = async (e, preventPopup) => {
        let data = null
        if (e) data = new FormData(e.target)
        setError({})
        if (selectedIntervention && selectedIntervention !== 'Cellule de crise' && !specialist) {
            return setError({ specialists: 'vous devez choisir un spécialistes pour ce type d\'intervention !'})
        }
        const name = company['@id']
        const address = newAddress
        const selectedStartDate = startDates.map((startDate => startDate.format('Y-M-D HH:mm:ss')))

        data = {
            company: name,
            address: formatAddress(address, newCoordonnate),
            remuneration: parseInt(remuneration),
            availableDates: selectedStartDate,
            interventionType: interventionTypes.find((type) => type.name === selectedIntervention) ?
                interventionTypes.find((type) => type.name === selectedIntervention)['@id'] :
                null,
            comment: comment,
            caseNumber: num,
            startTime: state.startHour && state.startMinute ? `${state.startHour}:${state.startMinute}` : '',
            endTime: state.endHour && state.endMinute ? `${state.endHour}:${state.endMinute}` : '',
        }

        if (selectedIntervention && selectedIntervention !== 'Cellule de crise') {
            data.specialist = specialist['@id']
            data.selectedDate = selectedStartDate[0]
        }

        if (selectedIntervention && selectedIntervention === 'Autre') data.label = label

        if (data.caseNumber && !preventPopup) {
            const checkCaseNumber = await apiFetch(`/interventions?caseNumber=${data.caseNumber}`)
            if (checkCaseNumber && checkCaseNumber['hydra:totalItems'] !== 0) {
                return setOpenConfirmPopup(true)
            }
        } else {
            setOpenConfirmPopup(false)
        }
        const createdIntervention = await createIntervention(data, true)

        if (createdIntervention && createdIntervention.uuid) {
            setText('L\'intervention a été crée')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setTimeout(() => {
                if (selectedIntervention && selectedIntervention !== 'Cellule de crise') return navigate(`/interventions`)
                navigate(`/interventions/${createdIntervention.uuid}/edit`)
            }, 2000)
        } else if (createdIntervention.violations) {
            const errorField = handleFormError(
                createdIntervention, 
                [
                    'caseNumber',
                    'label',
                    'startTime',
                    'endTime' ,'comment',
                    'company',
                    'address',
                    'availableDates',
                    'interventionType',
                    'remuneration'
                ]
            )
            setError(errorField)
            setText('Erreur lors de la création, un ou plusieurs champs sont manquants !')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText((createdIntervention && createdIntervention['hydra:description']) || 'Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    const handleChange = () => {
        setLoading(false)
    }

    const handleStartDateChange = (e, i) => {
        setLoading(false)
        const findDate = startDates.map((startDate, index) => {
            if (index === i) {
                return e
            }
            return startDate
        })
        setStartDates(findDate)
    }

    const handleChangeTime = (event) => {
        setState({ ...state, [event.target.name]: event.target.value })
    }

    const handleChangeInterventionTypes = (e) => {
        setSelectedIntervention(e.target.value);
    }

    const handleAddDate = () => {
        setStartDates([...startDates, moment()])
    }

    const handleRemoveDate = (e) => {
        const removeDate = startDates.filter(startDate => startDate._d !== e._d)
        setStartDates(removeDate)
    }

    const handleAccept = () => {
        handleCreate(null, true)
    }

    return (
        <Zoom in={zoom}>
            <Paper style={phoneResponsive ? {padding: 10} : {paddingTop: 8}}>
                <Typography
                    variant='h5'
                    color={darkState ? 'secondary' : 'primary'}
                    sx={{
                        ml: 2.5,
                        mb: 1
                    }}
                >
                    {id ? 'Modifier' : 'Ajouter'} une intervention
                </Typography>
                <Divider />
                {(id && intervention) || !id ? 
                    <form
                        id='submitForm'
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit}
                        onChange={handleChange}
                    >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                color={darkState ? 'secondary' : 'primary'}
                                label='N° Dossier'
                                value={num}
                                onChange={(e) =>setNum(e.target.value)}
                                error={!!error.caseNumber}
                                helperText={error.caseNumber}
                                sx={{
                                    width: 400,
                                    mt: 2,
                                    ml: 2,
                                }}
                            />
                            <div style={{marginLeft: 16}}>
                                {companies && <AutoCompleteCompany
                                    phoneResponsive={phoneResponsive}
                                    setLoading={setLoading}
                                    setOpenSnackbar={setOpenSnackbar}
                                    setText={setText}
                                    setTransition={setTransition}
                                    setSeverity={setSeverity}
                                    darkState={darkState}
                                    createCompany={createCompany}
                                    companies={companies}
                                    company={company}
                                    setCompany={setCompany}
                                />}
                            </div>
                            <FormHelperText style={{color: '#f44336', marginLeft: 30, marginBottom: 30}}>{error.company}</FormHelperText>
                            <div style={{marginLeft: 16}}>
                                <AutoCompleteAddress
                                    error={!!error.address}
                                    darkState={darkState}
                                    text={error.address}
                                    defaultValue={id && intervention.address.address}
                                    id="address"
                                    name="address"
                                    label="Adresse de l'intervention"
                                    setNewAddress={setNewAddress}
                                    setNewCoordonnate={setNewCoordonnate}
                                    required={true}
                                    style={{width: 400}}
                                />
                            </div>
                            <FormControl
                                color={darkState ? 'secondary' : 'primary'}
                                style={{marginTop: 30, marginLeft: 16, width: 400}}
                                error={!!error.interventionType}
                            >
                                <InputLabel id="select-error-label">Type d'intervention *</InputLabel>
                                <Select
                                    labelId="select-error-label"
                                    id="select-error"
                                    value={selectedIntervention}
                                    onChange={handleChangeInterventionTypes}
                                >
                                    <MenuItem value="">
                                      <em>Selectionnez un type d'intervention</em>
                                    </MenuItem>
                                    {interventionTypes && interventionTypes.map((interventionType) => (
                                        <MenuItem key={interventionType.id} value={interventionType.name}>{interventionType.name}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{error.interventionType}</FormHelperText>
                            </FormControl><br />
                            {selectedIntervention && selectedIntervention === 'Autre' &&
                            <>
                                <TextField
                                    value={label}
                                    onChange={(e) =>setLabel(e.target.value)}
                                    label="Préciser le type d'intervention"
                                    error={!!error.label}
                                    helperText={error.label}
                                    color={darkState ? 'secondary' : 'primary'}
                                    style={phoneResponsive ?
                                        {
                                            width: 195,
                                            marginBottom: 10,
                                            marginTop: 20,
                                            marginLeft: 16,
                                        } : {
                                            width: 400,
                                            marginBottom: 30,
                                            marginTop: 20,
                                            marginLeft: 16,
                                        }
                                    }
                                />
                                <br />
                            </>}
                            {specialists && selectedIntervention && selectedIntervention !== 'Cellule de crise' &&
                            <div style={{marginLeft: 16}}>
                                <Autocomplete
                                    value={specialist}
                                    onChange={(event, newSpecialist) => {
                                        setSpecialist(newSpecialist);
                                    }}
                                    inputValue={inputSpecialistValue}
                                    onInputChange={(event, newInputSpecialistValue) => {
                                        setInputSpecialistValue(newInputSpecialistValue);
                                    }}
                                    id="controllable-autocomplete"
                                    options={specialists}
                                    getOptionLabel={(option) => `${option.firstName} ${option.lastName.charAt(0)}`}
                                    style={{ width: 400, marginTop: 20, marginBottom: 10 }}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label="Spécialistes"
                                        color={darkState ? 'secondary' : 'primary'}
                                        error={!!error.specialists}
                                        helperText={error.specialists}
                                    />}
                                />
                                <br />
                            </div>}

                            <TextField
                                value={comment}
                                onChange={(e) =>setComment(e.target.value)}
                                label="Commentaire"
                                multiline
                                rows={5}
                                color={darkState ? 'secondary' : 'primary'}
                                style={phoneResponsive ?
                                    {
                                        width: 195,
                                        marginBottom: 10,
                                        marginTop: 30,
                                        marginLeft: 16,
                                    } : {
                                        width: 400,
                                        marginBottom: 30,
                                        marginTop: 30,
                                        marginLeft: 16,
                                    }
                                }
                                error={!!error.comment}
                                helperText={error.comment}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {startDates.map((startDate, i) => {
                                return <Grid container spacing={3} key={i}>
                                        <Grid item xs={12} sm={6}>
                                            <div style={{marginTop: 16}}>
                                                <DatePickerComponent
                                                    error={!!error.availableDates}
                                                    darkState={darkState}
                                                    color={!darkState && 'primary' }
                                                    handleDateChange={(e) => handleStartDateChange(e, i)}
                                                    selectedDate={startDate}
                                                    text="Date de début *"
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4} style={{marginTop: 25}}>
                                            <IconButton
                                                color='inherit'
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => handleRemoveDate(startDate)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                            })}
                            <Tooltip TransitionComponent={Zoom} title={`Ajouter une date`}>
                                <Fab
                                    color={darkState ? 'secondary' : 'primary'}
                                    aria-label="ajouter une date"
                                    onClick={handleAddDate}
                                    disabled={startDates.length === 3}
                                    style={{width: 35, height: 20, marginTop: 10}}
                                >
                                    <AddIcon />
                                </Fab>
                            </Tooltip>
                            <FormHelperText style={{color: 'red'}}>{error.availableDates}</FormHelperText>
                            <div style={{display: 'flex', marginTop: 60}}>
                                <Typography
                                    style={{
                                        color: darkState ? '#BBBEC5' : '#757575', marginRight: 20, marginTop: 15
                                    }}
                                >
                                    Heure de début * : 
                                </Typography>
                                <HourComponent
                                    error={error}
                                    darkState={darkState}
                                    handleChangeTime={handleChangeTime}
                                    state={state}
                                    time={'start'}
                                />
                                <FormControl color={darkState ? 'secondary' : 'primary'} error={!!error.startTime} sx={{width: 100}}>
                                    <InputLabel id="minute-label">minutes</InputLabel>
                                    <Select
                                        labelId="minute-label"
                                        id="minute"
                                        name="startMinute"
                                        value={state.startMinute}
                                        onChange={handleChangeTime}
                                    >
                                        <MenuItem value="">
                                            <em>Sélectionner les minutes</em>
                                        </MenuItem>
                                        <MenuItem value={'00'}>00</MenuItem>
                                        <MenuItem value={'30'}>30</MenuItem>
                                    </Select>
                                    <FormHelperText>{error.startTime}</FormHelperText>
                                </FormControl><br />
                            </div>
                            <div style={{display: 'flex', marginTop: 30}}>
                                <Typography
                                    style={{
                                        color: darkState ? '#BBBEC5' : '#757575',
                                        marginRight: 45,
                                        marginTop: 15
                                    }}
                                >
                                    Heure de fin * :
                                </Typography>
                                <HourComponent
                                    error={error}
                                    darkState={darkState}
                                    handleChangeTime={handleChangeTime}
                                    state={state}
                                    time={'end'}
                                />
                                <FormControl color={darkState ? 'secondary' : 'primary'} error={!!error.endTime} sx={{width: 100}}>
                                    <InputLabel id="minute-label">minutes</InputLabel>
                                    <Select
                                        labelId="minute-label"
                                        name="endMinute"
                                        id="minute"
                                        value={state.endMinute}
                                        onChange={handleChangeTime}
                                    >
                                        <MenuItem value="">
                                            <em>Sélectionner les minutes</em>
                                        </MenuItem>
                                        <MenuItem value={'00'}>00</MenuItem>
                                        <MenuItem value={'30'}>30</MenuItem>
                                    </Select>
                                    <FormHelperText>{error.endTime}</FormHelperText>
                                </FormControl>
                            </div>
                            <TextField
                                color={darkState ? 'secondary' : 'primary'}
                                type='number'
                                required
                                label='Rémunération'
                                value={remuneration}
                                onChange={(e) =>setRemuneration(e.target.value)}
                                error={!!error.remuneration}
                                helperText={error.remuneration}
                                sx={{mt: 4, width: 400}}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <ButtonGroup
                        color={darkState ? 'secondary' : "primary"}
                        variant="contained"
                        aria-label="contained primary button group"
                        sx={{m: 3,}}
                    >
                        <Button
                            disabled={loading}
                            type="submit"
                            id='submitButton'
                        >
                            Enregistrer
                        </Button>
                        <Button onClick={handleBack}>
                            Retour
                        </Button>
                    </ButtonGroup>
                </form> : 
                <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
                <ConfirmationPopup
                    open={openConfirPopup}
                    handleClose={() => setOpenConfirmPopup(false)}
                    handleAccept={handleAccept}
                    darkState={darkState}
                    title={'Ce numéro de dossier existe déjà !'}
                    content={'Voulez-vous continuer ?'}
                />
            </Paper>
        </Zoom>
    )
}

function HourComponent({ error, darkState, state, handleChangeTime, time }) {
    return (
        <FormControl
            color={darkState ? 'secondary' : 'primary'}
            error={time === 'end' ? !!error.endTime : !!error.startTime}
            sx={{width: 100, mr: 2}}
        >
            <InputLabel id="hour-label">Heure</InputLabel>
            <Select
                labelId="hour-label"
                name={time === 'end' ? "endHour" : 'startHour'}
                id="hour"
                value={time === 'end' ? state.endHour : state.startHour}
                onChange={handleChangeTime}
            >
                <MenuItem value="">
                    <em>Sélectionner une heure</em>
                </MenuItem>
                <MenuItem value={'01'}>01</MenuItem>
                <MenuItem value={'02'}>02</MenuItem>
                <MenuItem value={'03'}>03</MenuItem>
                <MenuItem value={'04'}>04</MenuItem>
                <MenuItem value={'05'}>05</MenuItem>
                <MenuItem value={'06'}>06</MenuItem>
                <MenuItem value={'07'}>07</MenuItem>
                <MenuItem value={'08'}>08</MenuItem>
                <MenuItem value={'09'}>09</MenuItem>
                <MenuItem value={'10'}>10</MenuItem>
                <MenuItem value={'11'}>11</MenuItem>
                <MenuItem value={'12'}>12</MenuItem>
                <MenuItem value={'13'}>13</MenuItem>
                <MenuItem value={'14'}>14</MenuItem>
                <MenuItem value={'15'}>15</MenuItem>
                <MenuItem value={'16'}>16</MenuItem>
                <MenuItem value={'17'}>17</MenuItem>
                <MenuItem value={'18'}>18</MenuItem>
                <MenuItem value={'19'}>19</MenuItem>
                <MenuItem value={'20'}>20</MenuItem>
                <MenuItem value={'21'}>21</MenuItem>
                <MenuItem value={'22'}>22</MenuItem>
                <MenuItem value={'23'}>23</MenuItem>
                <MenuItem value={'24'}>24</MenuItem>
            </Select>
            <FormHelperText>{time === 'end' ? error.endTime : error.startTime}</FormHelperText>
        </FormControl>
    )
}