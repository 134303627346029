import React, { useEffect, useRef, useState } from 'react';
import { Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Paper,
    TableRow,
    IconButton,
    Menu,
    MenuItem,
    Zoom,
    Toolbar,
    Typography,
    Button,
    LinearProgress,
    ButtonGroup,
    ThemeProvider,
    createTheme,
    TablePagination,
    Avatar,
} from '@mui/material';
import { useUsers } from '../hooks/users';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import * as locales from '@mui/material/locale';
import { TransitionLeft } from '../helpers/utils';
import { useNavigate } from 'react-router-dom';
import AddUser from './UserAccountItem/AddUser';
import DeletedConfirmationPopup from '../components/DeletedConfirmationPopup';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { apiFetch } from '../service/apiFetch';
import { isAuth } from '../service/security/isAuth';
import {isAdministrateur, isSuperAdmin} from "../service/security/hasRoles";

export default function UserAccount({ setOpenSnackbar, setText, setTransition, setSeverity, darkState, zoom, user, phoneResponsive }) {
    const mounted = useRef(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selected, setSelected] = useState(null);
    const [openCreateUser, setOpenCreateUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deletePopupOpen, setDeleteOpenPopup] = useState(false);
    const [page, setPage] = useState(0);
    const [locale] = useState('frFR');

    let navigate = useNavigate()
    document.title = 'Codex - Liste des utilisateurs'

    const {
        totalItems,
        view,
        users,
        fetchUsers,
        createUser,
        deleteUser,
    } = useUsers()

    useEffect(() => {
        (async () => {
            mounted.current = true
            await fetchUsers(null, () => mounted)
        })()

        return () => mounted.current = false
    }, [fetchUsers, users])

    const handleOpenMenu = (e, row) => {
        setSelected(row)
        setAnchorEl(e.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleModify = () => {
        navigate(`/users/${selected.uuid}/edit`)
    }

    const handleOpenCreateUser = () => {
        setOpenCreateUser(!openCreateUser)
    }

    const handleDelete = async () => {
        handleCloseMenu()
        setDeleteOpenPopup(false)
        const deletedUser = await deleteUser(selected.uuid)

        if (deletedUser && deletedUser.ok) {
            setText('L\'utilisateur a été supprimé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            setText((deletedUser && deletedUser['hydra:description']) || 'Erreur lors de la suppression')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    const handleDeletePopup = () => {
        handleCloseMenu()
        setDeleteOpenPopup(!deletePopupOpen)
    }

    const handleChangePage = async (event, newPage) => {
        setLoading(true)
        if (newPage > page) {
          await fetchUsers(view['hydra:next'])
        } else if (newPage < page) {
          await fetchUsers(view['hydra:previous'])
        }
        setLoading(false)
        setPage(newPage);
    };
  
    const onLoginAs = async (e, row) => {
        const loginAs = await apiFetch(`/impersonate?_switch_user=${row.email}`)
        if (loginAs && loginAs.token) {
            localStorage.setItem('REACT_ROLE', JSON.stringify(row.roles))
            localStorage.setItem('REACT_ROLE_ORIGIN', JSON.stringify(user.roles))
            localStorage.setItem('REACT_TOKEN_AUTH_ORIGIN', JSON.stringify(isAuth()))
            localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(loginAs))
            navigate('/dashboard')
        }
    }

    return (
        <Zoom in={zoom}>
            <Paper>
                <Toolbar>
                    <Typography component='div' color={darkState ? 'secondary' : "primary"} variant='h6' sx={{flex: '1 1 100%'}}>
                        Gérer les utilisateurs
                    </Typography>
                    {isAdministrateur() && (
                    !phoneResponsive ? <Button variant="contained" color={darkState ? 'secondary' : "primary"} size='small' onClick={handleOpenCreateUser}>
                        Ajouter un utilisateur
                    </Button> : 
                    <IconButton color='inherit' onClick={handleOpenCreateUser}>
                        <AddIcon />    
                    </IconButton>)}
                </Toolbar>
                <TableContainer style={phoneResponsive ? {maxWidth: 215} : {}}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={darkState ? {color: '#7fe1dd', fontWeight: 'bold'} : {color: '#05057C', fontWeight: 'bold'}}>Photo</TableCell>
                                <TableCell style={darkState ? {color: '#7fe1dd', fontWeight: 'bold'} : {color: '#05057C', fontWeight: 'bold'}} align="right">Nom</TableCell>
                                <TableCell style={darkState ? {color: '#7fe1dd', fontWeight: 'bold'} : {color: '#05057C', fontWeight: 'bold'}} align="right">Prénom</TableCell>
                                <TableCell style={darkState ? {color: '#7fe1dd', fontWeight: 'bold'} : {color: '#05057C', fontWeight: 'bold'}} align="right">Email</TableCell>
                                {isAdministrateur() && <TableCell style={darkState ? {color: '#7fe1dd', fontWeight: 'bold'} : {color: '#05057C', fontWeight: 'bold'}} align="right">Action</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                              {users && !!users.length && users.map((row) => (
                                  <TableRow key={row.uuid}>
                                      <TableCell component="th" scope="row">
                                        <Avatar src={`${row.photo && row.photo.contentUrl}`} aria-label="specialist">
                                            {row.firstName.charAt(0)}
                                        </Avatar>
                                      </TableCell>
                                      <TableCell align="right">{row.lastName}</TableCell>
                                      <TableCell align="right">{row.firstName}</TableCell>
                                      <TableCell align="right">{row.email}</TableCell>
                                      {isAdministrateur() &&
                                      <TableCell align="right">
                                            <ButtonGroup color={darkState ? 'secondary' : 'primary'}>
                                                <Button onClick={(e) => handleOpenMenu(e, row)}>
                                                    <MoreVertIcon />
                                                </Button>
                                                <Button onClick={(e) => onLoginAs(e, row)}>
                                                    <TransferWithinAStationIcon />
                                                </Button>
                                            </ButtonGroup>
                                      </TableCell>}
                                  </TableRow>
                              ))}
                        </TableBody>
                    </Table>
                    {!users && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
                    <AddUser 
                        user={user}
                        setOpenCreateUser={setOpenCreateUser}
                        createUser={createUser}
                        setText={setText}
                        setTransition={setTransition}
                        setSeverity={setSeverity}
                        setOpenSnackbar={setOpenSnackbar}
                        darkState={darkState}
                        open={openCreateUser}
                        handleClose={handleOpenCreateUser}
                    />
                    <SimpleMenu handleCloseMenu={handleCloseMenu} anchorEl={anchorEl} handleDeletePopup={handleDeletePopup} handleModify={handleModify} />
                    <DeletedConfirmationPopup handleAccept={handleDelete} open={deletePopupOpen} handleClose={handleDeletePopup} darkState={darkState} />
                </TableContainer>
                {users && <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                    <TablePagination
                        labelRowsPerPage=''
                        rowsPerPageOptions={[]}
                        component="div"
                        count={totalItems}
                        nextIconButtonProps={{ disabled: loading || page >= (totalItems / 30) - 1}}
                        backIconButtonProps={{ disabled: loading || page === 0 }} 
                        rowsPerPage={30}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </ThemeProvider>}
            </Paper>
        </Zoom>
    );
}

function SimpleMenu({ handleCloseMenu, anchorEl, handleModify, handleDeletePopup }) {
  
    return (
      <div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem onClick={handleModify}>Modifier</MenuItem>
          {isSuperAdmin() && <MenuItem onClick={handleDeletePopup}>Supprimer</MenuItem>}
        </Menu>
      </div>
    );
}
